import React, { useContext } from "react";
import LogoName from "../logoname/LogoName";
import Avatar from "../avatar/Avatar";
import LoginContext from "../../context/LoginContext";

const Header = ({ textTranslate }) => {
	const { userData } = useContext(LoginContext);

	return (
		<header className="headerIntranet full">
			<div className="headerSup g-container gtc2-s gtc3-m">
				<div className="seccionLogo gces1-s gces2-m jcs-s dg-s">
					<LogoName />
				</div>

				<Avatar
					name={userData.name}
					textTranslateLogout={textTranslate.Logout}
				/>
			</div>
			<div className="headerDown g-container acc-s">
				<div className="subtitle center-full bigger">
					{textTranslate?.Title}
				</div>
			</div>
		</header>
	);
};

export default Header;
