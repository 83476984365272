import { Skeleton } from "@mui/material";
import React from "react";

const SkeletonDown = () => {
	return (
		<>
			<td className="nameDownloadVersion pr16-s">
				<Skeleton
					variant="rectangular w250px-s center-cross"
					height="20px"
				/>
			</td>
			<td className="nameDownloadVersion pr16-s">
				<Skeleton
					variant="rectangular w250px-s center-cross"
					height="20px"
				/>
			</td>
			<td className="nameDownloadVersion pr16-s">
				<Skeleton variant="rectangular w80px-s center-cross" height="20px" />
			</td>
			<td className="nameDownloadVersion pr16-s">
				<Skeleton variant="rectangular w100px-s center-cross" height="20px" />
			</td>
			<td className="buttonDownload plr16-s">
				<div className="w24px-s center-cross">
					<Skeleton variant="rectangular w24px-s center-cross" height="20px" />
				</div>
			</td>
		</>
	);
};

export default SkeletonDown;
