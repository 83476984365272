import * as React from "react";

const EyeShow = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 415.59 268.3"
		style={{
			enableBackground: "new 0 0 415.59 268.3",
		}}
		xmlSpace="preserve"
		{...props}
	>
		<style>{".st1{display:inline}"}</style>
		<g id="Capa_2">
			<path d="M195.04 239.22c-20.35-.12-51.69-8.42-81.62-22.36-41.72-19.44-77.88-46.64-109.95-79.47-2.08-2.13-3.38-3.59-.59-6.43 40.16-40.79 85.61-73.45 140.44-91.72 24.75-8.25 50.17-12.01 76.26-10.45 32.77 1.96 63.39 11.75 92.51 26.45 37.68 19.02 70.79 44.28 100.22 74.46 4.35 4.46 4.42 3.87-.12 8.51-32.23 32.92-68.69 59.92-110.59 79.4-29.96 13.92-62.97 21.61-106.56 21.61zm86.45-104.98c.13-40.72-32.33-73.51-73-73.73-40.44-.22-73.25 32.49-73.51 73.27-.26 40.31 32.93 73.62 73.31 73.58 40.28-.04 73.07-32.8 73.2-73.12z" />
			<path d="M156.5 133.72c.29-29.01 23.24-51.82 51.93-51.63 29.29.19 51.83 23.34 51.57 52.97-.25 28.13-23.89 50.97-52.41 50.65-28.48-.33-51.37-23.62-51.09-51.99zm33.14-27.33c.04 10.41 8.42 18.86 18.65 18.83 10.28-.03 18.61-8.48 18.61-18.88 0-10.43-8.31-18.8-18.65-18.77-10.38.02-18.65 8.39-18.61 18.82z" />
		</g>
	</svg>
);

export default EyeShow;
