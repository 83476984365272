import React from "react";
import { LOGO_NOT_FOUND, URL_IMG } from "../../../const/Const";

const PreviousCard = ({ item, idorganization }) => {
	const handleErrorImg = (e) => {
		e.target.src = LOGO_NOT_FOUND;
		e.target.alt = "Logo not found";
	};
	return (
		<>
			<div className="dg-s gg1-s jcc-s p16 w50-s">
				<img
					className="w32px-s-i w64px-ipad-i w128px-lg-i jsc-s border"
					src={
						item.logourl === null || ""
							? LOGO_NOT_FOUND
							: `${URL_IMG}/organizations/${idorganization}/teams/${item.name}/${item.logourl}`
					}
					alt={`Logo equipo${item.namefull}`}
					loading="lazy"
					onError={handleErrorImg}
				/>
				<div className=" small-text jsc-s">{item.namefull}</div>
				{/* <div>{item.name}</div> */}
			</div>
		</>
	);
};

export default PreviousCard;
