const arrObjectConvert = (arr) => {
	let obj = {};
	Object.entries(arr).forEach(([key, value]) => {
        obj = value ;
	});

	return obj;
};

export default arrObjectConvert;
