import React from "react";
import "./css/Skeleton.scss";

const Skeleton = (props) => {
	const { variant, width, height, color } = props;

	const style = { width, height, color };

	return (
		<>
			<div className="content-skeleton">
				<div className={`skeleton ${variant}`} style={style}></div>
			</div>
		</>
	);
};

export default Skeleton;
