import React from "react";
import Skeleton from "../../skeleton/Skeleton";

const SkeletonCarrusel = () => {
	return (
		<>
			<div className="carousel-container">
				<h2 className="carousel-title title wth-100-from-s text-center-from-s fw100-s">
					Selecciona país
				</h2>
				<div className="carousel-wrapper">
					<div className="carousel-content-wrapper">
						<ul className="df-s jcc-s">
							<li>
								<Skeleton
									variant="rectangular w100px-s "
									height="58px"
								/>
								<div className="df-s jcc-s pb4-s">
									<Skeleton
										variant="rectangular w50px-s "
										height="12px"
									/>
								</div>{" "}
								<div className="df-s jcc-s">
									<Skeleton
										variant="rectangular w50px-s "
										height="10px"
									/>
								</div>
							</li>
							<li>
								<Skeleton
									variant="rectangular w100px-s "
									height="58px"
								/>{" "}
								<div className="df-s jcc-s pb4-s">
									<Skeleton
										variant="rectangular w50px-s "
										height="12px"
									/>
								</div>{" "}
								<div className="df-s jcc-s">
									<Skeleton
										variant="rectangular w50px-s "
										height="10px"
									/>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default SkeletonCarrusel;
