import * as React from "react";
const Download = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		style={{
			enableBackground: "new 0 0 135.74 132.77",
		}}
		viewBox="0 0 135.74 132.77"
		{...props}
	>
		<style>{".st3{fill:#fc7604}"}</style>
		<g id="Capa_2">
			<circle
				cx={67.87}
				cy={66.39}
				r={57.5}
				style={{
					fill: "none",
					stroke: "#e0e0e0",
					strokeWidth: 10,
					strokeMiterlimit: 10,
				}}
			/>
			<circle
				cx={67.87}
				cy={66.39}
				r={53.5}
				style={{
					fill: "#fff",
					stroke: "#a5a5a5",
					strokeMiterlimit: 10,
				}}
			/>
			<circle
				cx={67.87}
				cy={66.39}
				r={20}
				style={{
					fill: "#fff",
					stroke: "#fc7604",
					strokeWidth: 5,
					strokeMiterlimit: 10,
				}}
			/>
			<g id="Capa_3">
				<path d="M63.34 55.88h9.75v15h-9.75z" className="st3" />
				<path d="M68.37 76.89 78.9 66.66H56.84z" className="st3" />
			</g>
		</g>
	</svg>
);
export default Download;
