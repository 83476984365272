import React, { useContext, useMemo, useEffect } from "react";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import useGetPathBreadcrumbs from "../components/breadcrumb/hook/useGetPathBreadcrumbs";
import { Link, useParams } from "react-router-dom";
import { LanguageButton } from "../components/language/LanguageButton";
import LanguageContext from "../context/LanguageContext";
import Radar from "../components/grafic/Radar";
import BarTeamPeriod from "../components/grafic/BarTeamPeriod";
import useRequest from "../hook/useRequest";
import useUpdateState from "../hook/useUpdateState";
import GlobalContext from "../context/GlobalContext";
import { WEB_ALL } from "../const/Const";
import { ObjectToUrlParam } from "../components/utilities/ObjectToUrlParam";
import PreviousCard from "../components/main/card/PreviousCard";
import TitleSubSection from "../components/titlecomponent/TitleSubSection";
import MatchData from "../components/main/scheduleList/MatchData";
import LocalCard from "../components/main/card/LocalCard";
import moment from "moment";
import VisitorSpecialCard from "../components/main/card/VisitorSpecialCard";
import PreviousTeamsComparison from "../components/main/previousteamscomparison/PreviousTeamsComparison";
import PreviousTeamSkeleton from "../components/main/previousteamscomparison/skeleton/PreviousTeamSkeleton";
import Skeleton from "../components/skeleton/Skeleton";

const Previous = () => {
	const { textTranslate, handleLanguageButton } = useContext(LanguageContext);

	const {
		orderPage,
		idOrganization,
		pageRequest,
		seasonsCode,
		championsCode,
		teamcode1,
		teamcode2,
	} = useParams();

	const { getAllData } = useRequest();

	const { delStateStorage } = useUpdateState();

	const { setData, data } = useContext(GlobalContext);

	const { constructorCrumbs } = useGetPathBreadcrumbs();

	const { State, HeadToHead, RadarText, BarTeamPeriodText, LastMatchText } =
		textTranslate?.Previous;

	const params = useParams();

	const breadcrumbsList = useMemo(() => {
		return (
			<Breadcrumb
				dataPath={constructorCrumbs(
					params,
					parseInt(orderPage),
					parseInt(pageRequest),
					true
				)}
			/>
		);
	}, [constructorCrumbs, orderPage, params, pageRequest]);

	const dataRadarLocal =
		data?.PreviousByTeams?.average?.averageStatisticsTeam1;
	const dataRadarVisitor =
		data?.PreviousByTeams?.average?.averageStatisticsTeam2;

	const titleLocal = data?.PreviousByTeams?.teams?.[0]?.namefull;
	const titleVisitor = data?.PreviousByTeams?.teams?.[1]?.namefull;

	const dataBodyOptions = useMemo(() => {
		const items = {
			organization_id: idOrganization,
			page_request: pageRequest,
			season_code: seasonsCode,
			championship_code: championsCode,
			previous_code_team1: teamcode1,
			previous_code_team2: teamcode2,
		};
		return items;
	}, [
		idOrganization,
		pageRequest,
		seasonsCode,
		championsCode,
		teamcode1,
		teamcode2,
	]);

	useEffect(() => {
		setData(null);
		delStateStorage(["data"]);

		getAllData(
			WEB_ALL + "?" + ObjectToUrlParam(dataBodyOptions),
			"GET",
			null,
			null
		);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// const {} = data.PreviousByTeams;

	return (
		<>
			<div className="secondHeader">
				<div className="contentLogin"></div>
				<div className="contentBtnLanguage">
					<LanguageButton
						handleLanguageButton={handleLanguageButton}
					/>
				</div>
			</div>
			<div className="main gg2-s">
				{breadcrumbsList}
				<div className="w100-s">
					{!data?.PreviousByTeams && (
						<>
							<div className="w100-s">
								<PreviousTeamSkeleton />
								<div className="df-s jcc-s pt40-s fww-s">
									<div className="w100-s w40-ipad df-s jcc-s ptb32-s">
										<Skeleton variant="rectangular w180px-s h150px-s w220px-ipad" />
									</div>
									<div className="w100-s w40-ipad df-s jcc-s p16-s">
										<Skeleton variant="rectangular w180px-s h150px-s  w220px-ipad" />
									</div>
								</div>
								<div className="df-s jcc-s pt40-s">
									<div className="df-s w100-s -s jcc-s">
										<Skeleton variant="rectangular  w220px-s h150px-s w300px-ipad" />
									</div>
								</div>
								<div className="ptb16-s">
									<h2 className="titleText dg-s jcc-s pt40-s pb24-s">
										<Skeleton
											variant="rectangular"
											width="200px"
											height="28px"
										/>
									</h2>
									<div className="headToHead w80-s plr16-s">
										<div className="w100-s">
											<div className="matchList">
												<ul className="matchList__match">
													<li className="ptb16-s  h130px-s acc-s">
														<div className="match">
															<div className="w40-s">
																<Skeleton
																	variant="rectangular jss-s"
																	width="50px"
																	height="50px"
																/>
															</div>
															<div className="w10-s">
																<Skeleton
																	variant="rectangular jsc-s"
																	width="50px"
																	height="50px"
																/>
															</div>
															<div className="w40-s">
																<Skeleton
																	variant="rectangular jse-s"
																	width="50px"
																	height="50px"
																/>
															</div>
														</div>
													</li>
													<li className="ptb16-s  h130px-s acc-s">
														<div className="match">
															<div className="w40-s">
																<Skeleton
																	variant="rectangular jss-s"
																	width="50px"
																	height="50px"
																/>
															</div>
															<div className="w10-s">
																<Skeleton
																	variant="rectangular jsc-s"
																	width="50px"
																	height="50px"
																/>
															</div>
															<div className="w40-s">
																<Skeleton
																	variant="rectangular jse-s"
																	width="50px"
																	height="50px"
																/>
															</div>
														</div>
													</li>
													<li className="ptb16-s  h130px-s acc-s">
														<div className="match">
															<div className="w40-s">
																<Skeleton
																	variant="rectangular jss-s"
																	width="50px"
																	height="50px"
																/>
															</div>
															<div className="w10-s">
																<Skeleton
																	variant="rectangular jsc-s"
																	width="50px"
																	height="50px"
																/>
															</div>
															<div className="w40-s">
																<Skeleton
																	variant="rectangular jse-s"
																	width="50px"
																	height="50px"
																/>
															</div>
														</div>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					)}
					{data?.PreviousByTeams && (
						<>
							<div className="w100-s">
								<PreviousTeamsComparison
									data={data}
									idorganization={idOrganization}
									textTranslate={HeadToHead}
								/>
								<h2 className="titleText dg-s jcc-s pb32-s pt80-s ">
									{textTranslate.Previous.Graphs.Title}
								</h2>
								<div className="df-s jcc-s fww-s">
									<div className="w100-s w50-ipad">
										<Radar
											data={dataRadarLocal}
											title={titleLocal}
											color={"#f36c59"}
											name={titleLocal}
											textStateTranslate={RadarText}
										/>
									</div>
									<div className="w100-s w50-ipad">
										<Radar
											data={dataRadarVisitor}
											title={titleVisitor}
											name={titleVisitor}
											color={"#33a02c"}
											textStateTranslate={RadarText}
										/>
									</div>
								</div>
								<div className="df-s jcc-s pt40-s">
									<div className="w500px">
										<BarTeamPeriod
											averageParcialTeam1={
												data?.PreviousByTeams?.average
													?.averageParcialTeam1
											}
											averageParcialTeam2={
												data?.PreviousByTeams?.average
													?.averageParcialTeam2
											}
											max={15}
											nameTeam1={
												data?.PreviousByTeams
													?.teams?.[0]
											}
											nameTeam2={
												data?.PreviousByTeams
													?.teams?.[1]
											}
											textTranslate={BarTeamPeriodText}
										/>
									</div>
								</div>
								<div className="ptb16-s">
									<h2 className="titleText dg-s jcc-s pt40-s pb24-s">
										{LastMatchText.Title}
									</h2>
									<div className="headToHead w80-s plr16-s">
										<div className="w100-s">
											{data.PreviousByTeams?.matches?.map(
												(match) => (
													<React.Fragment
														key={match.code}
													>
														<div className="matchList">
															<ul
																className="matchList__match"
																key={match.code}
															>
																<li className="ptb16-s">
																	<Link
																		to={`/match/4/${pageRequest}/${idOrganization}/${match.seasoncode}/${match.championshipcode}/${match.code}`}
																		className="match"
																		target="_blank"
																	>
																		<div className="match__team">
																			<LocalCard
																				teams1={
																					match.namefullteam1
																				}
																				logo1={
																					match.logoteam1
																				}
																				teamshort={
																					match.nameteam1
																				}
																				shortvalue={
																					true
																				}
																			/>
																		</div>
																		<MatchData
																			data={moment(
																				match.date
																			).format(
																				"l"
																			)}
																			time={
																				moment(
																					match.hour,
																					"HH:mm"
																				).format(
																					"HH:mm"
																				) +
																				" h"
																			}
																			scoreteam1={
																				match.scoreteam1
																			}
																			scoreteam2={
																				match.scoreteam2
																			}
																			state={
																				match.state
																			}
																			textStateTranslate={
																				State[
																					match
																						.state
																				]
																			}
																			period={
																				match.period
																			}
																			textPeriodTranslate={
																				State.Period
																			}
																			city={
																				match.city
																			}
																			hour={
																				match.hour
																			}
																		/>
																		<div className="match__team match__team--right">
																			<VisitorSpecialCard
																				teams2={
																					match.namefullteam2
																				}
																				logo2={
																					match.logoteam2
																				}
																				teamshort={
																					match.nameteam2
																				}
																				shortvalue={
																					true
																				}
																			/>
																		</div>
																	</Link>
																</li>
															</ul>
														</div>
													</React.Fragment>
												)
											)}
										</div>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default Previous;
