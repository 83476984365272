import React, { createContext, useMemo, useState } from "react";

const RequestContext = createContext({});

export function RequestContextProvider({ children }) {
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState(false);
	const [code, setCode] = useState(null);
	const [errorHttp, setErrorHttp] = useState(false);

	const value = useMemo(
		() => ({
			loading,
			setLoading,
			message,
			setMessage,
			code,
			setCode,
			errorHttp,
			setErrorHttp,
		}),
		[loading, code, message, errorHttp]
	);

	return (
		<RequestContext.Provider value={value}>
			{children}
		</RequestContext.Provider>
	);
}

export default RequestContext;
