import React from "react";
import { Navigate } from "react-router-dom";
import useUser from "../hook/useUser";

const AccessRoute = ({ children }) => {
	const { isStateUser } = useUser();

	return isStateUser === "isLogged" ? (
		// <Navigate to="/organization" /> //reenvio tras login ok
		<Navigate to="/dashboard" /> //reenvio tras login ok
	) : (
		children
	);
};
export default AccessRoute;
