import React from "react";
import ReactECharts from "echarts-for-react";

const Radar = ({ data, title, name, color, textStateTranslate }) => {
	// Elimina el símbolo "#" si está presente
	let hex = color.replace("#", "");

	// Si el formato es de 3 caracteres (por ejemplo, #abc)
	if (hex.length === 3) {
		hex = hex
			.split("")
			.map((char) => char + char)
			.join("");
	}

	// Convierte los componentes hexadecimal a decimal
	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);

	// Retorna el color en formato rgba
	const hexColor = `rgba(${r}, ${g}, ${b}, 0.2)`;

	const dataRound = data?.map((item) => item.toFixed(2));

	const maxValue = Math.max(...dataRound); // Calcula el valor máximo general

	const option = {
		title: {
			text: title || "", // Título configurable
			left: "center", // Centra el título horizontalmente
			top: "top",
			textStyle: {
				// color: "#fff",
				// backgroundColor: "#999",
				// borderRadius: 3,
				// padding: [3, 5],
				fontSize: 14, 
			}, 
		},
		tooltip: {
			trigger: "axis",
		},
		// legend: {
		// 	left: "center",
		// 	ata: [name],
		// },
		radar: [
			{
				// name: {
				// 	textStyle: {
				// 		color: "#fff",
				// 		backgroundColor: "#999",
				// 		borderRadius: 3,
				// 		padding: [3, 5],
				// 		fontSize: 16, // Aquí ajustamos el tamaño del texto de los títulos del radar
				// 	},
				// },
				indicator: [
					// Indicadores configurables
					{
						name: textStateTranslate.Exclusions,
						max: maxValue + 2,
						min: 0,
					},
					{
						name: textStateTranslate.Turnovers,
						max: maxValue + 2,
						min: 0,
					},
					{
						name: textStateTranslate.Steals,
						max: maxValue + 2,
						min: 0,
					},
					{
						name: textStateTranslate.Assists,
						max: maxValue + 2,
						min: 0,
					},
					{
						name: textStateTranslate.Recovers,
						max: maxValue + 2,
						min: 0,
					},
				],
				center: ["50%", "50%"],
				radius: 90,
				splitNumber: maxValue + 1,
				alignTicks: false,
			},
		],
		series: [
			{
				type: "radar",
				// name:name,
				tooltip: {
					trigger: "item",
				},
				// Cambia el color del área rellenada
				areaStyle: {
					color: hexColor, // Color del área
				},
				// Cambia el color de las líneas y puntos
				itemStyle: {
					color: color, // Color de los puntos
					borderColor: color, // Color de las líneas
				},
				data: [
					{
						value: dataRound,
						name: name,
					},
				],
			},
		],
	};
	return (
		<>
			<ReactECharts option={option} />
		</>
	);
};
export default Radar;
