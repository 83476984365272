import React from "react";
import TabsPanel from "./TabsPanel";

const TabsSwitcher = ({
	nametabs,
	handleClickTab,
	active,
	classparent,
	classitem,
	classitemactive,
	classtext,
}) => {
	return (
		<>
			<div className={classparent}>
				{nametabs?.map((item) => (
					<React.Fragment key={item.tabposition}>
						<li
							className={
								active === `${item.tabposition}`
									? classitemactive
									: classitem
							}
							onClick={(e) => handleClickTab(e)}
							id={item.tabposition}
							name={item.name}
						>
							<p className={classtext}>{item.name}</p>
						</li>
					</React.Fragment>
				))}
			</div>
			<TabsPanel />
		</>
	);
};

export default TabsSwitcher;
