import React, { useContext } from "react";
import ReactDOM from "react-dom";
import "../modalusers/ModalUsers";
import DashContext from "../../../context/DashContent";

const ModalLoading = ({ children, elementById, loading, setLoading }) => {
	const { setErrorSelect } = useContext(DashContext);
	return ReactDOM.createPortal(
		<article className={`modal ${loading && "is-open"}`}>
			<div
				className={`contentModalUser ${loading ? "zoomIn" : "zoomOut"}`}
			>
				<div className="modalUser">
					<span
						className="close"
						onClick={() => {
							setLoading(false);
							setErrorSelect(false);
						}}
					>
						&times;
					</span>
					{children}
				</div>
			</div>
		</article>,
		document.getElementById(elementById)
	);
};

export default ModalLoading;
