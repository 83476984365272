import React from "react";
import LeagueRanking from "../../leagueranking/LeagueRanking";
import TitleSection from "../../titlecomponent/TitleSection";

const ScheduleStandings = ({
	title,
	selectChampionsData,
	ranking,
	textTranslate,
}) => {
	return (
		<>
			<div className="w100-s">
				<TitleSection title={title} />
				<LeagueRanking
					selectChampionsData={selectChampionsData}
					ranking={ranking}
					textTranslate={textTranslate}
				/>
			</div>
		</>
	);
};

export default ScheduleStandings;
