import React, { Fragment } from "react";

const StatisticsHeaderTeam = ({ textTranslate }) => {
	return (
		<>
			<div className="theadStatistics--b-top">
				<ul className="theadTeam">
					{" "}
					<li className="thead__item">
						<div className="head">
							<p className="head__number"></p>
							<p className="playercard__img"></p>
							<p className="head__player">
								{textTranslate.Team.HeadTable.Actions}
							</p>
						</div>
					</li>
					<li className="thead__item">
						{textTranslate.Team.HeadTable.fourkey}
					</li>
					<li className="thead__item">
						{textTranslate.Team.HeadTable.tenkey}
					</li>
					<li className="thead__item">
						{textTranslate.Team.HeadTable.twelvesecond}
					</li>
					<li className="thead__item">
						{textTranslate.Team.HeadTable.throwin}
					</li>
					<li className="thead__item">
						{textTranslate.Team.HeadTable.fortypos}
					</li>
					<li className="thead__item">
						{textTranslate.Team.HeadTable.nodrib}
					</li>
				</ul>
			</div>
			<div className="titleposition">
				<div className="courtbar"></div>
				<div className="courtposition">{textTranslate.Team.Title}</div>
				<div className="courtbar"></div>
			</div>
		</>
	);
};

export default StatisticsHeaderTeam;
