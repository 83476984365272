import React from "react";

const StatisticsHeaderTecnichal = ({ textTranslate }) => {
	return (
		<>
			<div className="theadStatistics--b-top">
				<ul className="thead">
					<li className="thead__item">
						<div className="head">
							<p className="head__number"></p>
							<p className="technical__img"></p>
							<p className="head__player">
								{textTranslate.Staff.HeadTable.Staff}
							</p>
						</div>
					</li>
					<li className="thead__item"></li>
					<li className="thead__item"></li>
					<li className="thead__item"></li>
					<li className="thead__item"></li>
					<li className="thead__item"></li>
					<li className="thead__item"></li>
					<li className="thead__item">
						{textTranslate.Staff.HeadTable.Position}
					</li>
				</ul>
			</div>
			<div className="titleposition">
				<div className="courtbar"></div>
				<div className="courtposition">{textTranslate.Title}</div>
				<div className="courtbar"></div>
			</div>
		</>
	);
};

export default StatisticsHeaderTecnichal;
