import React, { useContext } from "react";
import RequestContext from "../../../context/RequestContext";
import Loader from "../../../components/loader/Loader";
import ErrorIcon from "../../../components/img/ErrorIcon";

const LoadingModal = ({ HandleClosed, titleModal }) => {
	const { loading, message, errorHttp } = useContext(RequestContext);

	return (
		<div className="frameView">
			<div className="viewModal">
				{loading && (
					<>
						<h2 className="titleModal">{titleModal}</h2>
						<div className="contentLoader">
							<Loader />
						</div>
					</>
				)}
				{errorHttp && !loading && (
					<>
						<h2 className="titleModal">Error</h2>
						<div className="contentShowResponseIcon">
							<ErrorIcon />
							<p>{message.msnerror}</p>
						</div>
						<div className="contentButton">
							<div onClick={HandleClosed} className="buttonIcon">
								<div className="textIcon">Cerrar</div>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default LoadingModal;
