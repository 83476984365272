import * as React from "react";
const ErrorIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		style={{
			enableBackground: "new 0 0 457.05 457.89",
		}}
		viewBox="0 0 457.05 457.89"
		{...props}
	>
		<path
			d="M228.87 4.91c123.48.22 223.89 100.87 223.73 224.25-.16 123.64-101.16 224.24-224.69 223.82C104.43 452.57 4.15 351.81 4.45 228.46 4.75 105.03 105.48 4.69 228.87 4.91zM92.92 318.23c15.3 15.3 30.2 30.21 45.9 45.91l89.5-89.5c29.76 29.78 59.66 59.71 89.32 89.4 15.06-15.09 29.98-30.03 45.37-45.45-29.68-29.66-59.69-59.66-89.8-89.74 30.2-30.19 60.22-60.2 89.67-89.63-15.38-15.37-30.39-30.37-46.11-46.09l-90.06 90.06c-29.77-29.77-59.76-59.76-89.2-89.19-15.05 15.06-29.97 29.98-45.69 45.7 29.64 29.63 59.63 59.62 89.85 89.83-29.66 29.64-59.47 59.43-88.75 88.7z"
			style={{
				fill: "#e9473a",
			}}
		/>
	</svg>
);
export default ErrorIcon;
