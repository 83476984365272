import { useCallback } from "react";
import OrganizationCss from "../styles/css-json/css.json";

const useGetCss = () => {
	const getCssOrg = useCallback((org) => {
		let filterCss = OrganizationCss.find((item) => item.id === org);
		Object.entries(filterCss).forEach(([key, value]) => {
			if (key !== "id")
				document.documentElement.style.setProperty(key, value);
		});
	}, []);

	return { getCssOrg };
};

export default useGetCss;
