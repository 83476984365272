import React from "react";
import { Link } from "react-router-dom";
import "./styles/itemcarousel.css";
import { STATIC_IMG, OUTBOUND_WEB } from "../../const/Const";

const ItemCarousel = ({
	id,
	nameorganizations,
	namecountry,
	pagerequest,
	order,
	carouselTxt,
	// HomeOrganizations,
}) => {
	//Carga de imagenes para qeu el widget cargue las img estaticas correctamente
	const FlagCountry =
		OUTBOUND_WEB === "widget"
			? `${STATIC_IMG}/web/country/${namecountry}.png`
			: `/img/web/country/${namecountry}.png`;

	return (
		<>
			<li>
				<div className="item-carousel-container">
					<Link
						to={`/seasons/${order}/${pagerequest}/${id}`}
						className="item-carousel-link"
					>
						<img
							src={FlagCountry}
							alt={namecountry}
							loading="lazy"
						/>
						<h3 className="name-country">
							{/* {HomeOrganizations[namecountry]} */}
							{/* {carouselText[namecountry]} */}
							{carouselTxt[namecountry]}
						</h3>
						<p className="name-championsship smaller">
							{nameorganizations.toUpperCase()}
						</p>
					</Link>
				</div>
			</li>
		</>
	);
};

export default ItemCarousel;
