import React from "react";
import { FLAG_LANGUAGE, OUTBOUND_WEB } from "../../const/Const";

const ImgFlag = ({ language, country }) => {
	//Carga de imagenes para qeu el widget cargue las img estaticas correctamente
	const FlagCountry =
		OUTBOUND_WEB === "widget"
			? `${FLAG_LANGUAGE}/web/country/${country}.png`
			: `/img/web/country/${country}.png`;

	return (
		<>
			<img
				src={FlagCountry}
				alt={country}
				className="flagLanguage"
				loading="lazy"
			/>
			<p className="textLanguage">{language}</p>
		</>
	);
};

export default ImgFlag;
