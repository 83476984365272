import React from "react";
import { useParams } from "react-router-dom";
import { LOGO_NOT_FOUND, URL_IMG } from "../../../const/Const";

const ScoreCardMini = ({ logoteam, team, shortname }) => {
	// const border = {
	//     color: {
	//         borderBottom: '4px solid #' + props.colourteam
	//     }
	// };

	const { idOrganization } = useParams();

		const handleErrorImg = (e) => {
			e.target.src = LOGO_NOT_FOUND;
			e.target.alt = "Logo not found";
		};

	return (
		<div
			className="card mb8-s mb0-lg"
			// style={border.color}
		>
			<div className="card__logo">
				<img
					className="w16px-s-i w32px-ipad-i w128px-lg-i"
					src={
						logoteam === null || ""
							? LOGO_NOT_FOUND
							: `${URL_IMG}/organizations/${idOrganization}/teams/${shortname}/${logoteam}`
					}
					alt={`Logo ${team}`}
					loading="lazy"
					onError={handleErrorImg}
				/>
			</div>
			<div className="card__name normal">{team}</div>
		</div>
	);
};

export default ScoreCardMini;
