import React, { useCallback, useContext, useState } from "react";
import {
	GoogleReCaptcha,
	GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import GlobalContext from "../context/GlobalContext";
import useFormFieldValidation from "../hook/useFormFieldValidation";
import LogoName from "../components/logoname/LogoName";
import TitleSubSection from "../components/titlecomponent/TitleSubSection";
import RequestContext from "../context/RequestContext";
import ModalLoading from "../components/modals/modalloading/ModalLoading";
import LoadingModal from "../backend/component/authorizationcomponent/LoadingModal";
import useUser from "../hook/useUser";
import useScreenSize from "../hook/useScreemSize";
import { CONTACT_FORM } from "../const/Const";
import { LanguageButton } from "../components/language/LanguageButton";
import LanguageContext from "../context/LanguageContext";
import useRequest from "../hook/useRequest";

const ContactForm = () => {
	const { loading, message, errorHttp } = useContext(RequestContext);
	const { setLoading } = useUser();
	const { contactForm } = useRequest();

	const [tokenGoogle, setTokenGoogle] = useState();
	const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
	const { width } = useScreenSize();

	const { textTranslate, handleLanguageButton } = useContext(LanguageContext);

	const { Welcome, FormText } = textTranslate?.Contact;
	const { Modal } = textTranslate;

	const {
		formErrors,
		formData,
		setFormData,
		validateField,
		initialValues,
		isSubmitDisabled,
	} = useFormFieldValidation();

	// const testsize = "t4";

	const onVerify = useCallback(
		(tokenData) => {
			setTokenGoogle(tokenData);
		},
		[setTokenGoogle]
	);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleBlur = (e) => {
		const { name: inputName, value } = e.target;
		validateField(inputName, value);
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		// Hacemos una pausa de 3 segundos antes de enviar el formulario
		await new Promise((r) => setTimeout(r, 3000));

		// if (formData.surname) {
		// 	// console.log("False submit success!");
		// 	// setSubmitSuccess(true);
		// 	setFormData(initialValues);
		// 	setFormErrors(initialErrors);
		// 	return;
		// }

		// Comprobar si hay errores en los campos de entrada
		const hasErrors = Object.values(formErrors).every(
			(error) => error === ""
		);
		//quitamos el surname del array para que sirva de honeypot
		// const formDataWithoutSurname = { ...formData };
		// delete formDataWithoutSurname.surname;

		if (hasErrors) {
			// Ejecutar la acción onSubmit si no hay errores
			contactForm(
				CONTACT_FORM,
				"POST",
				{
					name: formData.name,
					email: formData.email,
					message: formData.message,
					surname: formData.surname,
					tokenGoogle: tokenGoogle,
				},
				null
			);
		} else {
		}

		setFormData(initialValues);
		setRefreshReCaptcha((r) => !r);
	};

	const imageUrl = `/img/web/contact/contact${
		width <= 1025 ? "_mobile" : ""
	}-min.jpg`;

	return (
		<>
			<GoogleReCaptchaProvider reCaptchaKey="6LdX98QpAAAAAF0hyPnajgM4opyn2V_VbIPhaMwN">
				<div className="secondHeader">
					<div className="contentLogin"></div>
					<div className="contentBtnLanguage">
						<LanguageButton
							handleLanguageButton={handleLanguageButton}
						/>
					</div>
				</div>
				<div className="main">
					{(loading || errorHttp) && (
						<ModalLoading
							elementById="modal"
							loading={loading}
							setLoading={setLoading}
						>
							<LoadingModal
								message={message}
								titleModal={Modal.Loading}
							/>
						</ModalLoading>
					)}
					{width <= 1025 ? (
						<div className="mainContact">
							<div className="contentTextContact">
								<h2>{Welcome.Title}</h2>
								<p
									className="textContact"
									dangerouslySetInnerHTML={{
										__html: Welcome.Text,
									}}
								/>
								<h2>{Welcome.Gratitude}</h2>
							</div>
							<div className="contentPicture">
								<img
									className="imageContact"
									src={imageUrl}
									alt="Foto contacto"
									loading="lazy"
								/>
							</div>
							<div className="contentContact">
								<div className="containerContact df-s jcc-s aic-s">
									<div className="cardForm mb3-s">
										<div className="contentTitle mt16-s">
											<h2>{FormText.Title}</h2>
											<p
												className="messageContact"
												dangerouslySetInnerHTML={{
													__html: FormText.Text,
												}}
											/>
										</div>

										<form>
											<div className="content-input mb8-s dg-s fww-s">
												<label className="form-label">
													{FormText.Name}
												</label>
												<input
													type="text"
													id="name"
													name="name"
													className={
														formErrors.name
															? "w100-s border-danger"
															: "w100-s"
													}
													onChange={handleChange}
													onBlur={handleBlur}
													value={formData.name}
													autoComplete="Name"
													placeholder={
														FormText.PlaceName
													}
												/>
												<div className="content-danger">
													{formErrors.name && (
														<div className="text-danger">
															{formErrors.name}
														</div>
													)}
												</div>
											</div>
											<div className="content-input mb8-s dg-s fww-s">
												<label
													className="form-label"
													htmlFor="form-label"
												>
													{FormText.Email}
												</label>
												<input
													type="email"
													id="email"
													name="email"
													className={
														formErrors.email
															? "w100-s border-danger"
															: "w100-s"
													}
													onChange={handleChange}
													onBlur={handleBlur}
													value={formData.email}
													placeholder={
														FormText.PlaceEmail
													}
												/>
												<div className="content-danger">
													{formErrors.email && (
														<p className="text-danger">
															{formErrors.email}
														</p>
													)}
												</div>
											</div>
											<div className="content-input mb8-s dg-s fww-s">
												<label className="form-label">
													{FormText.Message}
												</label>
												<textarea
													id="message"
													name="message"
													className={
														formErrors.message
															? "w100-s border-danger"
															: "w100-s"
													}
													onChange={handleChange}
													onBlur={handleBlur}
													value={formData.message}
													placeholder={
														FormText.PlaceMng
													}
												/>
												<div className="content-danger">
													{formErrors.message && (
														<div className="text-danger">
															{formErrors.message}
														</div>
													)}
												</div>
											</div>
											<div className="content-input mb8-s dg-s fww-s dn-s">
												<label className="form-label">
													Apellidos
												</label>
												<input
													type="text"
													name="surname"
													onChange={handleChange}
													value={formData.surname}
												/>
												<div className="content-danger">
													{formErrors.surname && (
														<p className="text-danger">
															{formErrors.surname}
														</p>
													)}
												</div>
											</div>

											<div>
												<GoogleReCaptcha
													onVerify={onVerify}
													refreshReCaptcha={
														refreshReCaptcha
													}
												/>
											</div>
											<button
												type="submit"
												className="btn btn-outline-primary text-center shadow-none mb-3 button gray-button-color small"
												disabled={isSubmitDisabled()}
												onClick={onSubmit}
											>
												{FormText.BtnText}
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="mainContact">
							<div className="contentTextContact">
								<h2>{Welcome.Title}</h2>
								<p
									className="textContact"
									dangerouslySetInnerHTML={{
										__html: Welcome.Text,
									}}
								/>
								<h2>{Welcome.Gratitude}</h2>
							</div>
							<div className="contentPicture">
								<div
									style={{
										backgroundImage: `url(${imageUrl})`,
									}}
									className="imageContact w100-s"
								>
									<div className="contentContact">
										<div className="containerContact df-s jcc-s aic-s">
											<div className="cardForm mb3-s">
												<div className="card-body"></div>
												<div className="contentTitle mt16-s">
													<h2>{FormText.Title}</h2>
													<p
														className="messageContact"
														dangerouslySetInnerHTML={{
															__html: FormText.Text,
														}}
													/>
												</div>
												<form>
													<div className="content-input mb8-s dg-s fww-s">
														<label className="form-label">
															{FormText.Name}
														</label>
														<input
															type="text"
															id="name"
															name="name"
															className={
																formErrors.name
																	? "w100-s border-danger"
																	: "w100-s"
															}
															onChange={
																handleChange
															}
															onBlur={handleBlur}
															value={
																formData.name
															}
															autoComplete="Name"
															placeholder={
																FormText.PlaceName
															}
														/>
														<div className="content-danger">
															{formErrors.name && (
																<div className="text-danger">
																	{
																		formErrors.name
																	}
																</div>
															)}
														</div>
													</div>
													<div className="content-input mb8-s dg-s fww-s">
														<label
															className="form-label"
															htmlFor="form-label"
														>
															{FormText.Email}
														</label>
														<input
															type="email"
															id="email"
															name="email"
															className={
																formErrors.email
																	? "w100-s border-danger"
																	: "w100-s"
															}
															onChange={
																handleChange
															}
															onBlur={handleBlur}
															value={
																formData.email
															}
															placeholder={
																FormText.PlaceEmail
															}
														/>
														<div className="content-danger">
															{formErrors.email && (
																<p className="text-danger">
																	{
																		formErrors.email
																	}
																</p>
															)}
														</div>
													</div>

													<div className="content-input mb8-s dg-s fww-s">
														<label className="form-label">
															{FormText.Message}
														</label>
														<textarea
															id="message"
															name="message"
															className={
																formErrors.message
																	? "w100-s border-danger"
																	: "w100-s"
															}
															onChange={
																handleChange
															}
															onBlur={handleBlur}
															value={
																formData.message
															}
															placeholder={
																FormText.PlaceMng
															}
														/>
														<div className="content-danger">
															{formErrors.message && (
																<div className="text-danger">
																	{
																		formErrors.message
																	}
																</div>
															)}
														</div>
													</div>
													<div className="content-input mb8-s dg-s fww-s dn-s">
														<label className="form-label">
															Apellidos
														</label>
														<input
															type="text"
															name="surname"
															onChange={
																handleChange
															}
															value={
																formData.surname
															}
														/>
													</div>
													<div>
														<GoogleReCaptcha
															onVerify={onVerify}
															refreshReCaptcha={
																refreshReCaptcha
															}
														/>
													</div>
													<button
														type="submit"
														className="btn btn-outline-primary text-center shadow-none mb-3 button gray-button-color small"
														disabled={isSubmitDisabled()}
														onClick={onSubmit}
													>
														{FormText.BtnText}
													</button>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</GoogleReCaptchaProvider>
		</>
	);
};

export default ContactForm;
