import React from "react";
import ReactECharts from "echarts-for-react";

const BarTeamPeriod = ({
	averageParcialTeam1,
	averageParcialTeam2,
	max,
	nameTeam1,
	nameTeam2,
	textTranslate,
}) => {
	// Convertir y filtrar
	const source = Object?.keys(averageParcialTeam1)
		.filter((period) => {
			const periodNum = parseInt(period.replace("P", "")); // Obtener el número del period
			// Filtrar: Si el period es del 1 al 4, debe ser mayor que 0 en ambos equipos
			if (periodNum <= 4) {
				return (
					averageParcialTeam1[period] > 0 &&
					averageParcialTeam2[period] > 0
				);
			}
			// Si el period es mayor a 4, permitir aunque haya 0 goles
			return true;
		})
		.map((period) => ({
			teams: period,
			[nameTeam1.name]: averageParcialTeam1[period]?.toFixed(2),
			[nameTeam2.name]: averageParcialTeam2[period]?.toFixed(2),
		}));

	// Función para calcular el valor máximo dinámico
	const getMaxValue = () => {
		const localMax = Math.max(
			...source.map((item) => item[nameTeam1.name])
		);
		const visitorMax = Math.max(
			...source.map((item) => item[nameTeam2.name])
		);
		return Math.ceil(Math.max(localMax, visitorMax)); // Devuelve el mayor valor redondeado entre local y visitor
	};

	const option = {
		title: {
			text: textTranslate.Title, // Título configurable
			left: "center", // Centra el título horizontalmente
			top: "bottom", // Ajusta la posición vertical (puedes usar 'top', 'middle', etc.)
			textStyle: {
				// color: "#fff",
				// backgroundColor: "#999",
				// borderRadius: 3,
				// padding: [3, 5],
				fontSize: 16,
				fontWeight: "normal",
			},
		},
		legend: {},
		tooltip: {},
		dataset: {
			dimensions: ["teams", nameTeam1.name, nameTeam2.name],
			source: source,
		},
		xAxis: { type: "category" },
		yAxis: {
			type: "value",
			name: "Try",
			min: 0,
			max: getMaxValue(),
			interval: Math.ceil(getMaxValue() / 5),
			axisLabel: {
				formatter: "{value}",
			},
		},
		// Cambia los colores de las barras con itemStyle
		series: [
			{
				type: "bar",
				itemStyle: {
					color: "#f36c59", // color de la barra local
				},
			},
			{
				type: "bar",
				itemStyle: {
					color: "#33a02c", // color de la barra visitante
				},
			},
		],
	};

	return (
		<>
			<ReactECharts option={option} />
		</>
	);
};

export default BarTeamPeriod;
