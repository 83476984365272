import { useCallback, useContext } from "react";
import doFetch from "../service/doFetch";
import RequestContext from "../context/RequestContext";
import GlobalContext from "../context/GlobalContext";
import SetItemResponse from "../components/utilities/SetItemResponse";
import useUpdateState from "./useUpdateState";

const useRequest = () => {
	const { setData, setVersionApp } = useContext(GlobalContext);

	const { setMessage, setCode, setLoading } = useContext(RequestContext);

	const { delStateStorage } = useUpdateState();

	const getAllData = useCallback(
		(endpoint, method, body, token) => {
			setLoading(true);
			doFetch(endpoint, method, body, token)
				.then((response) => {
					if (Boolean(response) && response.length !== 0) {
						setData(response);
						SetItemResponse(response, "data", 30, "second");
					}
					setLoading(false);
				})
				.catch((error) => {
					delStateStorage(["data"]);
					if (error.name === "AbortError") {
						setLoading(false);
						setCode(308);
						setMessage(true);
					} else {
						setLoading(false);
						setCode(error.Code);
						setMessage(true);
					}
				});
		},
		[setData, setCode, setMessage, delStateStorage, setLoading]
	);

	const getVersion = useCallback(
		(endpoint, method, body, token) => {
			setLoading(true);
			doFetch(endpoint, method, body, token)
				.then((response) => {
					if (Boolean(response) && response.length !== 0) {
						setVersionApp(response);
						SetItemResponse(response, "versionapp", 30, "second");
					}
					setLoading(false);
				})
				.catch((error) => {
					delStateStorage(["versionapp"]);
					if (error.name === "AbortError") {
						setLoading(false);
						setCode(308);
						setMessage(true);
					} else {
						setLoading(false);
						setCode(error.Code);
						setMessage(true);
					}
				});
		},
		[setCode, setMessage, delStateStorage, setVersionApp, setLoading]
	);

	const contactForm = useCallback(
		(endpoint, method, body, token) => {
			setLoading(true);
			doFetch(endpoint, method, body)
				.then((response) => {
					const { Code, Message } = response;
					if (Code === 202 /*&& User.role_id === 1*/) {
						setMessage(Message);
					}
					setLoading(false);
				})
				.catch((error) => {
					delStateStorage(["versionapp"]);
					if (error.name === "AbortError") {
						setLoading(false);
						setCode(308);
						setMessage(true);
					} else {
						setLoading(false);
						setCode(error.Code);
						setMessage(true);
					}
				});
		},
		[setCode, setMessage, delStateStorage, setLoading]
	);

	return { getAllData, getVersion, contactForm };
};

export default useRequest;
