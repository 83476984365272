import React from "react";
// import "./App.css";
import "./styles/index.scss";
// import "./index.css";
import { RequestContextProvider } from "./context/RequestContext";
import { GlobalContextProvider } from "./context/GlobalContext";
import WebRoute from "./route/WebRoute";
import { LanguageContextProvider } from "./context/LanguageContext";
import WidgetRoute from "./route/WidgetRoute";
import { OUTBOUND_WEB } from "./const/Const";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const App = () => {
	const railway =
		OUTBOUND_WEB === "web" ? (
				<WebRoute />
		) : (
			<WidgetRoute />
		);
	return (
		<>
			<RequestContextProvider>
				<GlobalContextProvider>
					<LanguageContextProvider>
						<>
							{/* <WebRoute /> */}
							{/* <WidgetRoute /> */}
							{railway}
						</>
					</LanguageContextProvider>
				</GlobalContextProvider>
			</RequestContextProvider>
		</>
	);
};

export default App;
