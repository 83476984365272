import React, { Fragment } from 'react';

const ScorePeriodPoints = ( props ) => {

    const seachword=`score${props.condition}P`;

    //Filtramos la busqueda de key por la condicion de  scorelocal o scorevisitor
    const seachbyword = Object.keys(props.matchscore).filter((key) =>
        key.includes(seachword)
    );

    //Eliminamos las  key que nos sobran para tener los marcadores parciales
    const filtered = Object.fromEntries(
        Object.entries(props.matchscore).filter(([key, val]) =>
            seachbyword.includes(key)
        )
    );

    const finalComponent = []
    let periodPartial
    if ( props.periodplay <= 4 ) {
        periodPartial = 4;
    } else {
        periodPartial = props.periodplay; 
    }
        for (let i = 1; i < periodPartial + 1; i++) {
            const composeword = seachword + i;
            finalComponent.push(
                <li className="periodpoints" key={composeword}>
                    {filtered[composeword]}
                </li>
            );
        }

    return <>{finalComponent}</>;
};

export default ScorePeriodPoints;
