import React from 'react';

const ScoreTimeOut = ({ timeout, textTmeTranslate }) => {
	let  timeoutArray = [];
	for (let i = 1; i < 5; i++) {
		i <= timeout
			? timeoutArray.push(<li className="light--red" key={i}></li>)
			: timeoutArray.push(<li className="light" key={i}></li>);
	}

	return (
		<div className="timeout">
            <div className="timeout__label">{textTmeTranslate}</div>
			<ul className="timeout__light">{timeoutArray}</ul>
		</div>
	);
};

export default ScoreTimeOut;
