import { useContext } from "react";
import EyeHide from "../../components/img/EyeHide";
import EyeShow from "../../components/img/EyeShow";
import LoginContext from "../../context/LoginContext";
import useUser from "../../hook/useUser";
import TitleSubSection from "../../components/titlecomponent/TitleSubSection";
import RequestContext from "../../context/RequestContext";
import LogoName from "../../components/logoname/LogoName";
import LoadingModal from "../component/authorizationcomponent/LoadingModal";
import ModalLoading from "../../components/modals/modalloading/ModalLoading";
import { Link } from "react-router-dom";
import LanguageContext from "../../context/LanguageContext";
import { LanguageButton } from "../../components/language/LanguageButton";

const LoginPage = () => {
	const {
		passwordShown,
		password,
		setPassword,
		email,
		setEmail,
		emailError,
		setEmailError,
		passwordError,
		setPasswordError,
		rememberMe,
		setRememberMe,
	} = useContext(LoginContext);

	const { textTranslate, handleLanguageButton } = useContext(LanguageContext);

	const { Login, Modal } = textTranslate;

	const { login, setLoading, togglePasswordVisiblity } = useUser();
	const { loading, message, errorHttp, code } = useContext(RequestContext);

	const testsize = "t4";

	const handleEmailChange = (event) => {
		const newEmail = event.target.value;
		setEmail(newEmail);
		setEmailError("");
	};

	const handlePasswordChange = (event) => {
		const newPassword = event.target.value;
		setPassword(newPassword);
		setPasswordError("");
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		// // Si el usuario ha marcado la casilla de "Recordarme", almacenar el nombre de usuario en localStorage
		// if (rememberMe) {
		// 	localStorage.setItem("remenber", email);
		// } else {
		// 	// Si no se ha marcado la casilla de "Recordarme", eliminar el nombre de usuario del localStorage
		// 	localStorage.removeItem("remenber");
		// }

		const isValidEmail = (email) => {
			const emailRegex =
				/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
			return emailRegex.test(email);
		};

		const isValidPassword = (password) => {
			const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*/;
			return passwordRegex.test(password);
		};

		let msgEmailError = "";
		let msgPasswordError = "";

		if (email.length === 0 || !isValidEmail(email)) {
			msgEmailError = Login.ErrorEmail;
		}

		if (
			password.length < 4 ||
			password.length > 100 ||
			!isValidPassword(password)
		) {
			msgPasswordError = Login.ErrorPassword;
		}

		setEmailError(msgEmailError);
		setPasswordError(msgPasswordError);

		if (!msgEmailError && !msgPasswordError) {
			// If there are no errors, proceed with login
			login({ email, password });
			setEmailError("");
			setPasswordError("");
		}
	};

	return (
		<>
			<div className="secondHeader">
				<div className="contentLogin"></div>
				<div className="contentBtnLanguage">
					<LanguageButton
						handleLanguageButton={handleLanguageButton}
					/>
				</div>
			</div>
			<div className="mainForm">
				{(loading || errorHttp) && (
					<ModalLoading
						elementById="modal"
						loading={loading}
						setLoading={setLoading}
					>
						<LoadingModal
							message={message}
							titleModal={Modal.Loading}
						/>
					</ModalLoading>
				)}

				<div className="containerForm df-s jcc-s aic-s">
					<div className="cardForm mb3-s">
						<div className="card-body">
							<LogoName />
							<div className="contentTitle mt16-s">
								<TitleSubSection
									title={Login.Title}
									sizetext={testsize}
								/>
							</div>
							<form onSubmit={handleSubmit}>
								<div className="content-input mb8-s dg-s fww-s">
									<label className="form-label">
										{Login.Email}
									</label>
									<input
										type="text"
										className={
											emailError
												? "w100-s border-danger"
												: "w100-s"
										}
										title="Ingresa una dirección de correo válido"
										placeholder={Login.PlaceEmail}
										onChange={handleEmailChange}
										value={email}
										autoComplete="email"
									/>
								</div>
								<div className="content-danger">
									{emailError && (
										<p className="text-danger">
											{emailError}
										</p>
									)}
								</div>
								<div className="content-input mb8-s mt8-s dg-s fww-s">
									<label className="form-label">
										{Login.Password}
									</label>
									<div className="content-password df-s">
										<input
											id="password"
											type={
												passwordShown
													? "text"
													: "password"
											}
											placeholder={Login.PlacePassword}
											title="Ingresa una password válida"
											onChange={handlePasswordChange}
											// onInput={handleInputReset}
											value={password}
											className={
												passwordError
													? "w100-s border-danger"
													: "w100-s"
											}
											autoComplete="current-password"
										/>
										<button
											className="contentIconInput"
											onMouseDown={
												togglePasswordVisiblity
											}
											onMouseUp={togglePasswordVisiblity}
										>
											{passwordShown ? (
												<EyeShow
													width="20px"
													height="20px"
													className="input-icon password"
												/>
											) : (
												<EyeHide
													width="20px"
													height="20px"
													className="input-icon password"
												/>
											)}
										</button>
									</div>
								</div>
								<div className="content-danger-extra">
									{passwordError && (
										<p className="text-danger">
											{passwordError}
										</p>
									)}{" "}
									{code === 401 && (
										<p className="text-danger">{message}</p>
									)}
									{code === 202 && message && (
										<p className="text-danger">{message}</p>
									)}
								</div>
								{/* <div className="content-input mb8-s mt8-s dg-s fww-s">
								<label>
									Recordarme:
									<input
										type="checkbox"
										checked={rememberMe}
										onChange={(event) =>
											setRememberMe(event.target.checked)
										}
									/>
								</label>
							</div> */}
								<div className="df-s jcc-s m16-s ">
									<button
										className="btn btn-outline-primary text-center shadow-none mb-3 button gray-button-color small"
										type="submit"
									>
										{Login.BtnText}
									</button>
								</div>
							</form>
							<div className="contentForgetPassword">
								<Link to="/forgot-password">
									{Login.Forgot}
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default LoginPage;
