import React from "react";
import StateViewColor from "../../utilities/StateViewColor";
import ScoreCardMini from "./ScoreCardMini";
import ScoreChrone from "./ScoreChrone";
import ScoreNumber from "./ScoreNumber";
import ScorePartialByPeriod from "./ScorePartialByPeriod";
import ScoreTimeOut from "./ScoreTimeOut";

const ScorePoints = ({
	matchscore,
	state,
	localteam,
	logolocalteam,
	colourteam1,
	visitorteam,
	logovisitorteam,
	colourteam2,
	textTranslate,
	shortnamelocal,
	shortnamevisitor,
}) => {
	return (
		<div className="points">
			<div className="points__home">
				<div className="points__cardmini is-none-from-lg ">
					<ScoreCardMini
						team={localteam}
						logoteam={logolocalteam}
						colourteam={colourteam1}
						shortname={shortnamelocal}
					/>
				</div>
				<ScoreNumber
					name={textTranslate.Local}
					score={matchscore.scoreteam1}
				/>
				<ScoreTimeOut
					timeout={matchscore.timeoutT1}
					textTmeTranslate={textTranslate.TimeOut}
				/>
			</div>
			<div className="points__general">
				<ScoreChrone
					periodplay={matchscore.periodplay}
					time={matchscore.tiempo}
					textPeriodranslate={textTranslate}
				/>
				<div className="period">
					<ScorePartialByPeriod
						teamclassName="home"
						team={matchscore.team1}
						scoreteam={matchscore.scoreteam1}
						matchscore={matchscore}
						condition="local"
					/>
					<ScorePartialByPeriod
						teamclassName="visitor"
						team={matchscore.team2}
						scoreteam={matchscore.scoreteam2}
						matchscore={matchscore}
						condition="visitor"
					/>
				</div>
				<StateViewColor
					state={state}
					textStateTranslate={textTranslate.State[state]}
					position="stadeMatch"
				/>
			</div>
			<div className="points__visitor">
				<div className="points__cardmini is-none-from-lg ">
					<ScoreCardMini
						team={visitorteam}
						logoteam={logovisitorteam}
						colourteam={colourteam2}
						shortname={shortnamevisitor}
					/>
				</div>
				<ScoreNumber
					name={textTranslate.Visitor}
					score={matchscore.scoreteam2}
				/>
				<ScoreTimeOut
					timeout={matchscore.timeoutT2}
					textTmeTranslate={textTranslate.TimeOut}
				/>
			</div>
		</div>
	);
};

export default ScorePoints;
