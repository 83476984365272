import React from "react";
import IconDownload from "../imagenesReact/IconDownload";
import moment from "moment";
import { URL_DOMIAN } from "../../const/Const";

const ItemDownload = ({ itemrows, testFolder }) => {
	return (
		<>
			<td className="nameDownloadVersion pr16-s">{itemrows.namefile}</td>
			<td className="nameDownloadVersion pr16-s justifyContent">
				{itemrows.description}
			</td>
			<td className="nameDownloadVersion pr16-s">{itemrows.version}</td>
			<td className="nameDownloadVersion pr16-s">
				{moment(`${itemrows.releasedate}`).format("L")}
			</td>
			<td className="buttonDownload plr16-s">
				<div className="w24px-s center-cross">
					<a
						href={
							URL_DOMIAN +
							`public/download/${testFolder}/${itemrows.namefile}`
						}
						download
					>
						<IconDownload />
					</a>
				</div>
			</td>
		</>
	);
};

export default ItemDownload;
