import React, { useEffect, useState } from "react";
import TitleSubSection from "../../titlecomponent/TitleSubSection";
import ScheduleLeagueItemMatchPlayoff from "./ScheduleLeagueItemMatchPlayoff";

const SchedulePlayoff = ({
	matchList,
	textTranslate,
	nextOrderPage,
	state,
	modal,
	setdata,
	delstatestorage,
}) => {
	const [numberMatchday, setNumberMatchday] = useState(null);
	const [numberMatchtype, setNumberMatchtype] = useState(null);

	//obtenemos las jornadas de los partidos
	useEffect(() => {
		let savematchdays = [];
		//recorremos todos los partidos para almacenar los leaguematchday
		matchList?.map(
			(matchday) =>
				(savematchdays = [...savematchdays, matchday.leaguematchday])
		);
		//eliminados los duplicados para guardar solo los dias diferentes
		setNumberMatchday([...new Set(savematchdays)]);
	}, [matchList]); // eslint-disable-line react-hooks/exhaustive-deps

	//obtenemos los diferentes tipos de partidos en los playoff
	useEffect(() => {
		let savematchtype = [];
		//recorremos todos los partidos para almacenar los leaguematchday
		matchList?.map(
			(matchtype) =>
				(savematchtype = [...savematchtype, matchtype.matchtype])
		);
		//eliminados los duplicados para guardar solo los dias diferentes
		setNumberMatchtype([...new Set(savematchtype)]);
	}, [matchList]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{numberMatchday?.map((item) => (
				<div key={item} >
					<TitleSubSection
						key={item}
						title={`${textTranslate.Matchdays} ${item}`}
						underline={"true"}
					/>
					{numberMatchtype?.map((typeMatch) => (
						<ScheduleLeagueItemMatchPlayoff
							key={item}
							matchdays={item}
							typeMatch={typeMatch}
							matchList={matchList}
							nextOrderPage={nextOrderPage}
							textTranslate={textTranslate}
							stateTextTranslate={state}
							modalTextTranslate={modal}
							setdata={setdata}
							delstatestorage={delstatestorage}
						/>
					))}
				</div>
			))}
		</>
	);
};

export default SchedulePlayoff;
