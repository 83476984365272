import { Link } from "react-router-dom";
import { OUTBOUND_WEB, STATIC_IMG } from "../../../const/Const";

const ItemSeasons = ({
	nameorganizations,
	name,
	code,
	idOrganization,
	pageRequest,
	hadleClickItemSeason,
	nextOrderPage,
}) => {
	//Carga de imagenes para qeu el widget cargue las img estaticas correctamente
	const LogoOrg =
		OUTBOUND_WEB ===
			"widget" ? `${STATIC_IMG}/web/logo/organizations/${nameorganizations.toLowerCase()}.png`:
		`/img/web/logo/organizations/${nameorganizations.toLowerCase()}.png`;

	return (
		<>
			<li className="itemSeasons">
				<div className="item-season-container">
					<Link
						to={`/champions/${nextOrderPage}/${pageRequest}/${idOrganization}/${code}`}
						className="item-season-link"
						onClick={hadleClickItemSeason}
					>
						<img
							src={LogoOrg}
							alt={`Logo ${name}`}
							loading="lazy"
						/>
						<p className="name-season smaller">{name}</p>
						<h3 className="name-country">
							{nameorganizations.toUpperCase()}
						</h3>
					</Link>
				</div>
			</li>
		</>
	);
};

export default ItemSeasons;
