import React from "react";
import Skeleton from "../../../skeleton/Skeleton";

const SkeletonMatchList = () => {
	return (
		<>
			<div className="matchList__match">
				<div key={1}>
					<div className="match">
						<div className="match__team">
							<div className="card ">
								<div className="card__logo">
									<Skeleton
										variant="circular"
										width="32px"
										height="32px"

									/>
								</div>
								<div className="card__name">
									<Skeleton
										variant="rectangular"
										width="100px"
										height="15px"
									/>
								</div>
							</div>
						</div>
						<div className="match__data p8-s">
							<Skeleton
								variant="rectangular"
								width="80px"
								height="50px"
							/>
						</div>
						<div className="match__team match__team--right">
							<div className="card card--right">
								<div className="card__logo card__logo--right">
									<Skeleton
										variant="circular"
										width="32px"
										height="32px"
									/>
								</div>
								<div className="card__name card__name--right">
									<Skeleton
										variant="rectangular"
										width="100px"
										height="15px"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div key={2}>
					<div className="match">
						<div className="match__team">
							<div className="card ">
								<div className="card__logo">
									<Skeleton
										variant="circular"
										width="32px"
										height="32px"
									/>
								</div>
								<div className="card__name">
									<Skeleton
										variant="rectangular"
										width="100px"
										height="15px"
									/>
								</div>
							</div>
						</div>
						<div className="match__data p8-s">
							<Skeleton
								variant="rectangular"
								width="80px"
								height="50px"
							/>
						</div>
						<div className="match__team match__team--right">
							<div className="card card--right">
								<div className="card__logo card__logo--right">
									<Skeleton
										variant="circular"
										width="32px"
										height="32px"
									/>
								</div>
								<div className="card__name card__name--right">
									<Skeleton
										variant="rectangular"
										width="100px"
										height="15px"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div key={3}>
					<div className="match">
						<div className="match__team">
							<div className="card ">
								<div className="card__logo">
									<Skeleton
										variant="circular"
										width="32px"
										height="32px"
									/>
								</div>
								<div className="card__name">
									<Skeleton
										variant="rectangular"
										width="100px"
										height="15px"
									/>
								</div>
							</div>
						</div>
						<div className="match__data p8-s">
							<Skeleton
								variant="rectangular"
								width="80px"
								height="50px"
							/>
						</div>
						<div className="match__team match__team--right">
							<div className="card card--right">
								<div className="card__logo card__logo--right">
									<Skeleton
										variant="circular"
										width="32px"
										height="32px"
									/>
								</div>
								<div className="card__name card__name--right">
									<Skeleton
										variant="rectangular"
										width="100px"
										height="15px"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div key={4}>
					<div className="match">
						<div className="match__team">
							<div className="card ">
								<div className="card__logo">
									<Skeleton
										variant="circular"
										width="32px"
										height="32px"
									/>
								</div>
								<div className="card__name">
									<Skeleton
										variant="rectangular"
										width="100px"
										height="15px"
									/>
								</div>
							</div>
						</div>
						<div className="match__data p8-s">
							<Skeleton
								variant="rectangular"
								width="80px"
								height="50px"
							/>
						</div>
						<div className="match__team match__team--right">
							<div className="card card--right">
								<div className="card__logo card__logo--right">
									<Skeleton
										variant="circular"
										width="32px"
										height="32px"
									/>
								</div>
								<div className="card__name card__name--right">
									<Skeleton
										variant="rectangular"
										width="100px"
										height="15px"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SkeletonMatchList;
