import React, { Fragment } from "react";

const StatisticsHeaderPlayer = ({ title, textTranslate }) => {
	return (
		<>
			<div
				// className={`theadStatistics ${
				// 	props.title === "Bench" ? "b-top" : " "
				// }`}
				className="theadStatistics--b-top"
			>
				<ul className="thead">
					<li className="thead__item">
						<div className="head">
							<p className="head__number">Nº</p>
							<p className="playercard__img"></p>
							<p className="head__player">
								{textTranslate.Players.HeadTable.Players}
							</p>
						</div>
					</li>
					<li className="thead__item">
						{textTranslate.Players.HeadTable.Tries}
					</li>
					<li className="thead__item">
						{textTranslate.Players.HeadTable.Retrive}
					</li>
					<li className="thead__item">
						{textTranslate.Players.HeadTable.Excluded}
					</li>
					<li className="thead__item">
						{textTranslate.Players.HeadTable.Steals}
					</li>
					<li className="thead__item">
						{textTranslate.Players.HeadTable.Assistance}
					</li>
					<li className="thead__item">
						{textTranslate.Players.HeadTable.Lost}
					</li>
					<li className="thead__item">
						{textTranslate.Players.HeadTable.LvCourt}
					</li>
					<li className="thead__item">
						{textTranslate.Players.HeadTable.Class}
					</li>
				</ul>
			</div>
			<div className="titleposition">
				<div className="courtbar"></div>
				<div className="courtposition">{title}</div>
				<div className="courtbar"></div>
			</div>
		</>
	);
};

export default StatisticsHeaderPlayer;
