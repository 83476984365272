import React from "react";
import LeadersSection from "./LeadersSection";
// import PropTypes from 'prop-types';
import FirstUppercase from "../../utilities/FirstUppercase";
import { URL_IMG } from "../../../const/Const";
import TitleSection from "../../titlecomponent/TitleSection";
import { useParams } from "react-router-dom";

const LeadersCard = ({ title, data, value }) => {
	const { idOrganization } = useParams();

	console.log(data);
	return (
		<div className="leader">
			<div className="leader__item">
				<ul className="leaderList">
					<li className="leaderList__header">
						<TitleSection title={title} underline={"true"} />
					</li>
					{(data === null || Object.keys(data).length === 0) && (
						<div className="w100-s text-center-s">
							No existen datos
						</div>
					)}
					{data?.map((item, index) => (
						<li
							className={
								index === 0
									? "leaderList__lider"
									: "leaderList__other"
							}
							key={index}
						>
							<LeadersSection
								position={index + 1}
								// logourl={item.name}
								teamshort={item.name}
								team={item.namefull}
								name={FirstUppercase(item.nameplayer)}
								image={`${URL_IMG}/organizations/${idOrganization}/players/${item.name}/${item.imageUrl}`}
								value={item[value]}
								leader={index === 0 ? true : false}
							/>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

// LeadersCard.defaultProps = {
// 	name: "No encontrado",
// 	image: "https://quadstatistics.clickerti.es/not-found/image-not-found.jpg",
// 	dorsal: "No encontrado",
// };

export default LeadersCard;
