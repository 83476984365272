import React, { createContext, useState, useMemo } from "react";

const GlobalContext = createContext({});

export function GlobalContextProvider({ children }) {
	const [data, setData] = useState(() =>
		JSON.parse(window.sessionStorage.getItem("data"))
	);

	const [versionApp, setVersionApp] = useState(() =>
		JSON.parse(window.sessionStorage.getItem("versionapp"))
	);

	const [pagerequest, setPagerequest] = useState(1);

	const [tabmatch, setTabMatch] = useState(
		JSON.parse(window.sessionStorage.getItem("tabmatch"))
			? JSON.parse(window.sessionStorage.getItem("tabmatch"))
			: "1"
	);

	const [tabSchedule, setTabSchedule] = useState(
		JSON.parse(window.sessionStorage.getItem("tabschedule"))
			? JSON.parse(window.sessionStorage.getItem("tabschedule"))
			: "1"
	);

	const [previousURLRef, setPreviousURLRef] = useState(
		sessionStorage.getItem("previousURLRef") !== null
			? JSON.parse(sessionStorage.getItem("previousURLRef"))
			: ""
	);

	const [currentPageRef, setCurrentPageRef] = useState(
		sessionStorage.getItem("currentPageRef") !== null
			? JSON.parse(sessionStorage.getItem("currentPageRef"))
			: ""
	);

	const [optionMatchSelect, setOptionMatchSelect] = useState(
		sessionStorage.getItem("optionMatchSelect") !== null
			? JSON.parse(sessionStorage.getItem("optionMatchSelect"))
			: {}
	);

	const [countries, setCountries] = useState(() =>
		JSON.parse(window.sessionStorage.getItem("countries"))
	);

	const [allUserOrg, setAllUserOrg] = useState(() =>
		JSON.parse(window.sessionStorage.getItem("alluserorg"))
	);

	

	const value = useMemo(
		() => ({
			data,
			setData,
			pagerequest,
			setPagerequest,
			tabmatch,
			setTabMatch,
			tabSchedule,
			setTabSchedule,
			previousURLRef,
			setPreviousURLRef,
			currentPageRef,
			setCurrentPageRef,
			optionMatchSelect,
			setOptionMatchSelect,
			versionApp,
			setVersionApp,
			countries,
			setCountries,
			allUserOrg,
			setAllUserOrg,
		}),
		[
			data,
			pagerequest,
			tabmatch,
			tabSchedule,
			previousURLRef,
			currentPageRef,
			optionMatchSelect,
			versionApp,
			countries,
			allUserOrg,
		]
	);

	return (
		<GlobalContext.Provider value={value}>
			{children}
		</GlobalContext.Provider>
	);
}

export default GlobalContext;
