import React from "react";

const StatisticLineAccion = ({
	data,
	colourteam1,
	colourteam2,
	textTranslate,
}) => {
	const stylecss1 = {
		color: {
			backgroundColor: "#" + colourteam1,
		},
	};
	const stylecss2 = {
		color: {
			backgroundColor: "#" + colourteam2,
		},
	};

	return (
		<>
			{data?.map((item, index) => (
				<React.Fragment key={index}>
					<div className="allStatisticsTeams__list" key={index}>
						<div className="allStatisticsTeams__item h40px-s">
							<div
								className={
									item.col1 === 0 || ""
										? "card is-hidden-from-s"
										: "card"
								}
							>
								<div
									className="card__color w6px-s"
									style={stylecss1.color}
								></div>
								<div className="card__number card__number--bold">
									{item.col1}
								</div>
							</div>
							<div className="statisticsTitle">
								<div className="statisticsTitle__name fw700-s">
									{textTranslate.Actions[item.accion]}
								</div>
							</div>
							<div
								className={
									item.col2 === 0 || ""
										? "card card--right is-hidden-from-s"
										: "card card--right"
								}
							>
								<div className="card__number card__number--bold">
									{item.col2}
								</div>
								<div
									className="card__color card__color--right w6px-s"
									style={stylecss2.color}
								></div>
							</div>
						</div>
					</div>
				</React.Fragment>
			))}
		</>
	);
};

export default StatisticLineAccion;
