import React from 'react'
import { useParams } from 'react-router-dom';
import { LOGO_NOT_FOUND, URL_IMG } from '../../../const/Const';

const ScoreCardSpecial = ({ team, colourteam, logoteam, shortname }) => {
	const stylecss = {
		color: {
			backgroundColor: "#" + colourteam,
		},
	};
	const { idOrganization } = useParams();

		const handleErrorImg = (e) => {
			e.target.src = LOGO_NOT_FOUND;
			e.target.alt = "Logo not found";
		};

	return (
		<div className="card card--specialPos">
			<div
				className="card__color card__color--specialPos w12px-s"
				style={stylecss.color}
			></div>
			<div className="card__logo card__logo--specialPos">
				<img
					className="w32px-s-i w65px-ipad-i w90px-lg-i"
					src={
						logoteam === null || ""
							? LOGO_NOT_FOUND
							: `${URL_IMG}/organizations/${idOrganization}/teams/${shortname}/${logoteam}`
					}
					alt={`Logo ${team}`}
					loading="lazy"
					onError={handleErrorImg}
				/>
			</div>

			<div className="card__vertical-line card__vertical-line--right is-none-to-lg"></div>
			<div className="card__name card__name--specialPos normal">
				{team}
			</div>
		</div>
	);
};

export default ScoreCardSpecial
