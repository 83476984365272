import React, { useContext } from "react";
import Header from "../../components/header/Header";
import Dash from "../../components/img/Dash";
import Download from "../../components/img/Download";
import { Link } from "react-router-dom";
import Users from "../../components/img/Users";
import ModalLoading from "../../components/modals/modalloading/ModalLoading";
import LoadingModal from "../component/authorizationcomponent/LoadingModal";
import RequestContext from "../../context/RequestContext";
import useUser from "../../hook/useUser";
import LanguageContext from "../../context/LanguageContext";
import { LanguageButton } from "../../components/language/LanguageButton";

const DashBoardPage = () => {
	const { loading, message, errorHttp } = useContext(RequestContext);
	const { setLoading } = useUser();

	const { textTranslate, handleLanguageButton } = useContext(LanguageContext);
	const { Modal, Dashboard } = textTranslate;

	return (
		<>
			<div className="secondHeader">
				<div className="contentLogin"></div>
				<div className="contentBtnLanguage">
					<LanguageButton
						handleLanguageButton={handleLanguageButton}
					/>
				</div>
			</div>
			<div className="mainIntranet">
				{(loading || errorHttp) && (
					<ModalLoading
						elementById="modal"
						loading={loading}
						setLoading={setLoading}
					>
						<LoadingModal
							message={message}
							titleModal={Modal?.Looading}
						/>
					</ModalLoading>
				)}
				<Header textTranslate={Dashboard} />
				<div className="contentIntranet">
					{/* <NavIntranet /> */}
					{/* <div className="contentOrganization">
						<h3 className="pt16 pb32 center-cross big-text">
							Dashboard
						</h3>
					</div> */}
					{/* <div className="dash-card">
						<div className="dash-image">
							<Dash />
						</div>
						<div className="dash-details">
							<h2 className="dash-title">
								{Dashboard.SectionCode.Title}
							</h2>
							<p className="dash-description">
								{Dashboard.SectionCode.Description}
							</p>
							<Link to="/authorizations" className="button">
								{Dashboard.SectionCode.BtnText}
							</Link>
						</div>
					</div> */}
					<div className="dash-card">
						<div className="dash-image">
							<Download />
						</div>
						<div className="dash-details">
							<h2 className="dash-title">
								{Dashboard.SectionDownload.Title}
							</h2>
							<p className="dash-description">
								{Dashboard.SectionDownload.Description}
							</p>
							<Link to="/download" className="button">
								{Dashboard.SectionDownload.BtnText}
							</Link>
						</div>
					</div>
					{/* <div className="dash-card">
						<div className="dash-image">
							<Users />
						</div>
						<div className="dash-details">
							<h2 className="dash-title">
								{" "}
								{Dashboard.SectionUser.Title}
							</h2>
							<p className="dash-description">
								{Dashboard.SectionUser.Description}
							</p>
							<Link to="/user" className="button">
								{Dashboard.SectionUser.BtnText}
							</Link>
						</div>
					</div> */}
				</div>
			</div>
		</>
	);
};

export default DashBoardPage;
