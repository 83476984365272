export default async function doFetch(endpoint, method, body, token) {
	let headers = new Headers();
	headers.append("Accept", "application/json");
	headers.append("Content-Type", "application/json");
	headers.append("Origin", "*");
	headers.append("X-Origin", "web");
	headers.append("Accept-Language", navigator.language);

	let request;
	request = {
		method: method,
		headers: headers,
	};

	if (token) headers.append("Authorization", `Bearer ${token}`);

	//Comprobamos si llegan body o lo eliminamos para envio
	if (body && method !== "GET")
		request = { ...request, body: JSON.stringify(body) };

	//Cambiamos el estado de loadin para mostrar el cargador.
	// loading = true
	const controller = new AbortController();
	//peticion fetch con opciones agregadas
	setTimeout(() => controller.abort(), 40000);

	return await fetch(endpoint, request, { signal: controller.signal }).then(
		(res) => {
			return res.json();
		}
	);
}
