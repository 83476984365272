import React from "react";

const TitleSection = ({ title, subtitle, underline }) => {
	return (
		<div
			className={
				underline === "true"
					? "titleSection h40px-s"
					: "titleSection h40px-s titleSection--freeBorder"
			}
		>
			{subtitle === undefined ? (
				<h2 className="titleSection__text titleSection__text--t2 pt16-s">
					{title}
				</h2>
			) : (
				<h2 className="titleSection__text titleSection__text--t2 ">
					{title} - <span>{subtitle}</span>
				</h2>
			)}
		</div>
	);
};

export default TitleSection;
