import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import ScoreBoard from "../components/main/scoreBoard/ScoreBoard";
import { WEB_ALL } from "../const/Const";
import StatisticsTeamGlobal from "../components/main/statisticsTeamGlobal/StatisticsTeamGlobal";
import HistoryMatch from "../components/main/historyMatch/HistoryMatch";
import StatisticsAll from "../components/main/statisticTeamByPlayer/StatisticsAll";
import TabsSwitcher from "../components/tabscomponent/TabsSwitcher";
import TabsPanel from "../components/tabscomponent/TabsPanel";
import GlobalContext from "../context/GlobalContext";
import useRequest from "../hook/useRequest";
import SkeletonScoreBoard from "../components/main/scoreBoard/skeleton/SkeletonScoreBoard";
import SkeletonStadisticGlobal from "../components/main/statisticTeamByPlayer/skeletonStadisticGlobal/SkeletonStadisticGlobal";
import LeaderGroup from "../components/main/importantAction/LeaderGroup";
import RefereeMatch from "../components/main/refereeMatch/RefereeMatch";
import useUpdateState from "../hook/useUpdateState";
import { ObjectToUrlParam } from "../components/utilities/ObjectToUrlParam";
import useGetPathBreadcrumbs from "../components/breadcrumb/hook/useGetPathBreadcrumbs";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import arrObjectConvert from "../components/utilities/arrObjectConvert";
import useCss from "../hook/useGetCss";
import LanguageContext from "../context/LanguageContext";
import { LanguageButton } from "../components/language/LanguageButton";

const Match = () => {
	// console.log("render match");
	const {
		orderPage,
		idOrganization,
		pageRequest,
		seasonsCode,
		championsCode,
		matchCode,
	} = useParams();

	const { getCssOrg } = useCss();

	useEffect(() => {
		getCssOrg(pageRequest);
	}, [getCssOrg, pageRequest]);

	const params = useParams();

	const { constructorCrumbs } = useGetPathBreadcrumbs();

	const { getAllData } = useRequest();

	const { delStateStorage } = useUpdateState();

	const { setTabMatch, data, setData, tabmatch } = useContext(GlobalContext);

	const { textTranslate, handleLanguageButton } = useContext(LanguageContext);

	const {
		Scoreboard,
		Referee,
		TabSchedule,
		Statistic,
		Team,
		History,
		Standout,
	} = textTranslate.Match;

	const dataBodyOptions = useMemo(() => {
		const items = {
			organization_id: idOrganization,
			page_request: pageRequest,
			season_code: seasonsCode,
			championship_code: championsCode,
			match_code: matchCode,
		};
		return items;
	}, [idOrganization, pageRequest, seasonsCode, championsCode, matchCode]);

	useEffect(() => {
		setData(null);
		delStateStorage(["data"]);

		getAllData(
			WEB_ALL + "?" + ObjectToUrlParam(dataBodyOptions),
			"GET",
			null,
			null
		);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// const show = optionMatchSelect.state !== "PREVIUS" ? true : false;

	const breadcrumbsList = useMemo(() => {
		return (
			<Breadcrumb
				dataPath={constructorCrumbs(
					params,
					parseInt(orderPage),
					parseInt(pageRequest)
				)}
			/>
		);
	}, [constructorCrumbs, orderPage, params, pageRequest]);

	const matchsListFilter = useCallback(() => {
		if (data.MatchsListChampionship) {
			return data.MatchsListChampionship.filter(
				(item) => item.code === matchCode
			);
		}
	}, [data, matchCode]);

	const handleClickTabMatch = useCallback(
		(e) => {
			e.preventDefault();
			setTabMatch(e.currentTarget.id);
			window.sessionStorage.setItem(
				"tabmatch",
				JSON.stringify(e.currentTarget.id)
			);
		},
		[setTabMatch]
	);

	return (
		<>
			<div className="secondHeader">
				<div className="contentLogin"></div>
				<div className="contentBtnLanguage">
					<LanguageButton
						handleLanguageButton={handleLanguageButton}
					/>
				</div>
			</div>
			<div className="main">
				{breadcrumbsList}
				{!data && <SkeletonScoreBoard />}
				{data?.ScoreByMatch &&
					data?.MatchsListChampionship &&
					matchCode && (
						<ScoreBoard
							matchData={arrObjectConvert(data.ScoreByMatch)}
							selectMatchData={arrObjectConvert(
								matchsListFilter()
							)}
							textTranslate={Scoreboard}
						/>
					)}
				<div className="contentReferee w100-s">
					{data?.RefereeCommissionerByMatch && (
						<RefereeMatch
							dataReferee={data.RefereeCommissionerByMatch}
							textTranslate={Referee}
						/>
					)}
				</div>
				<div className="statistic w100-s">
					<TabsSwitcher
						nametabs={[
							{ name: TabSchedule.Tab1, tabposition: "1" },
							{ name: TabSchedule.Tab2, tabposition: "2" },
							{ name: TabSchedule.Tab3, tabposition: "3" },
							{ name: TabSchedule.Tab4, tabposition: "4" },
						]}
						handleClickTab={handleClickTabMatch}
						active={tabmatch}
						classparent="df-s h50px-s jcc-s"
						classtext="tab-text bdright"
						classitem="df-s tab-item jcc-s acc-s fww-s bgnone"
						classitemactive="df-s tab-item jcc-s acc-s fww-s tab-item-active bgnone"
					/>
					<TabsPanel>
						<div
							className={
								tabmatch === "1"
									? "content-panel db-s w100-s"
									: "is-none-from-s"
							}
						>
							{/* {!data && <SkeletonStadisticGlobal />} */}
							{data &&
								data.StatisticsByPlayerMatchLocal !== null &&
								data.StatisticsByPlayerMatchVisitor !== null &&
								Statistic !== null &&
								data.MatchsListChampionship &&
								matchCode && (
									<>
										<StatisticsAll
											selectMatchData={arrObjectConvert(
												matchsListFilter()
											)}
											StatisticsByPlayerMatchLocal={
												data?.StatisticsByPlayerMatchLocal
											}
											StatisticsByMatchTeamLocal={
												data?.StatisticsByMatchTeamLocal
											}
											TechnicalTeamLocal={
												data?.TechnicalTeamLocal
											}
											StatisticsByPlayerMatchVisitor={
												data?.StatisticsByPlayerMatchVisitor
											}
											StatisticsByMatchTeamVisitor={
												data?.StatisticsByMatchTeamVisitor
											}
											TechnicalTeamVisitor={
												data?.TechnicalTeamVisitor
											}
											textTranslate={Statistic}
										/>
									</>
								)}
						</div>
						<div
							className={
								tabmatch === "2"
									? "content-panel db-s w100-s"
									: "is-none-from-s"
							}
						>
							{!data && <SkeletonStadisticGlobal />}
							{data && data.MatchsListChampionship && (
								<>
									<StatisticsTeamGlobal
										selectMatchData={arrObjectConvert(
											matchsListFilter()
										)}
										statisticsByMatchCode={
											data?.StatisticsByMatchCode
										}
										textTranslate={Team}
									/>
								</>
							)}
						</div>
						<div
							className={
								tabmatch === "3"
									? "content-panel db-s w100-s"
									: "is-none-from-s"
							}
						>
							{!data && <SkeletonStadisticGlobal />}
							{data && data.MatchsListChampionship && (
								<HistoryMatch
									selectMatchData={arrObjectConvert(
										matchsListFilter()
									)}
									historyByMatch={data?.HistoryByMatch}
									textTranslate={History}
								/>
							)}
						</div>
						<div
							className={
								tabmatch === "4"
									? "content-panel db-s w100-s"
									: "is-none-from-s"
							}
						>
							{data && (
								<LeaderGroup
									tries={data?.Tries}
									recover={data?.Recovers}
									assist={data?.Assists}
									textTranslate={Standout}
								/>
							)}
						</div>
					</TabsPanel>
				</div>
			</div>
		</>
	);
};

export default Match;
