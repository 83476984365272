import React from "react";
import LocalCardWtihColor from "../card/LocalCardWithColor";
import VisitorCardWithColor from "../card/VisitorCardWithColor";
import StatisticLineAccion from "./StatisticLineAccion/StatisticLineAccion";
import { IsWhite } from "../../utilities/WatchColor";

const StatisticsTeamGlobal = ({
	selectMatchData,
	statisticsByMatchCode,
	textTranslate,
}) => {
	return (
		<>
			{statisticsByMatchCode && (
				<div className="allStatisticsTeams b-all">
					<div className="allStatisticsTeams__header b-bottom">
						<div className="cardTeam h48px-s">
							<LocalCardWtihColor
								colourteam={IsWhite(
									selectMatchData.colourteam1.slice(3)
								)}
								teamname={selectMatchData.nameteam1}
								logo={selectMatchData.logoteam1}
								teamshort={selectMatchData.localshortname}
							/>
							<VisitorCardWithColor
								colourteam={IsWhite(
									selectMatchData.colourteam2.slice(3)
								)}
								teamname={selectMatchData.nameteam2}
								logo={selectMatchData.logoteam2}
								teamshort={selectMatchData.visitorshortname}
							/>
						</div>
					</div>
					<StatisticLineAccion
						data={statisticsByMatchCode}
						colourteam1={IsWhite(
							selectMatchData.colourteam1.slice(3)
						)}
						colourteam2={IsWhite(
							selectMatchData.colourteam2.slice(3)
						)}
						textTranslate={textTranslate}
					/>
				</div>
			)}
		</>
	);
};

export default StatisticsTeamGlobal;
