import ReactDOM from "react-dom";
import "./css/modal.scss";

const Modal = ({ children, elementById, isModal, setModalShow }) => {
	return ReactDOM.createPortal(
		<article className={`modal ${isModal && "is-open"}`}>
			<div
				className={`modal-container ${isModal ? "zoomIn" : "zoomOut"}`}
			>
				<div className="modal-close">
					<button
						className="button-close"
						onClick={() => setModalShow(false)}
					>
						X
					</button>
				</div>
				<div className="content-children">{children}</div>
				<div className="modal-button-accept">
					<button
						className=" button-accept"
						onClick={() => setModalShow(false)}
					>
						Aceptar
					</button>
				</div>
			</div>
		</article>,
		document.getElementById(elementById)
	);
};

export default Modal;
