import * as React from "react";
const IconDownload = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		style={{
			enableBackground: "new 0 0 256.01 256.01",
		}}
		viewBox="0 0 256.01 256.01"
		{...props}
	>
		<path
			d="M0 128.03c0-30.16-.01-60.31 0-90.47C.02 15.24 15.32.01 37.76.01 97.9 0 158.05 0 218.2.01 240.87.01 256 15.22 256 38c.01 59.98.01 119.96 0 179.94 0 23-15.14 38.06-38.25 38.06-59.98.01-119.96.01-179.94 0C15.16 256 .01 240.76 0 218v-89.97zm136.01 13.16v-18.78c0-20.48.04-40.96-.03-61.44-.02-5.3-3.58-8.94-8.17-8.81-4.44.13-7.59 3.62-7.8 8.69-.05 1.16-.01 2.33-.01 3.5v76.81c-8.97-11.07-17.04-21.18-25.28-31.15-3.18-3.85-8.13-4.4-11.52-1.66-3.56 2.87-4.1 7.5-.87 11.54 12.99 16.26 26.04 32.46 39.21 48.57 3.79 4.63 8.9 4.55 12.87.1 2.21-2.48 4.21-5.16 6.3-7.75 10.77-13.34 21.58-26.65 32.29-40.04 4.01-5.01 3.55-10.12-.93-12.93-3.69-2.31-7.93-1.35-11.36 2.84-8 9.78-15.9 19.63-24.7 30.51zM128 187.91H96.5c-14.33 0-28.67-.05-43 .04-5.1.03-8.47 3.35-8.48 7.89-.01 4.27 3.23 7.56 7.99 8.02 1.16.11 2.33.04 3.5.04 47.66 0 95.33.01 142.99-.02 1.98 0 4.05-.03 5.91-.6 4.62-1.43 6.75-6.08 4.95-10.34-1.66-3.93-4.82-5.07-8.87-5.05-24.49.06-48.99.02-73.49.02z"
			style={{
				fill: "#e9455e",
			}}
		/>
	</svg>
);
export default IconDownload;
