import React from "react";
import Skeleton from "../../../skeleton/Skeleton";

const PreviousTeamSkeleton = () => {
	return (
		<>
			<h2 className="titleText dg-s jcc-s pb24-s">
				<Skeleton variant="rectangular" width="300px" height="28px" />
			</h2>
			<div className="headToHead w80-s plr16-s">
				<div className="df-s w100-s jcc-s p8-s border-bottom">
					<div className="dg-s gg1-s jcc-s p16 w50-s">
						<div className="w32px-s-i w64px-ipad-i w128px-lg-i jsc-s"></div>
						<Skeleton
							variant="rectangular"
							width="128px"
							height="128px"
						/>
						<div className=" small-text jsc-s">
							<Skeleton
								variant="rectangular"
								width="128px"
								height="30px"
							/>
						</div>
					</div>
					<div className="bigger acc-s p16 fw500-s">
						{" "}
						<Skeleton
							variant="rectangular"
							width="24px"
							height="24px"
						/>
					</div>
					<div className="dg-s gg1-s jcc-s p16 w50-s">
						<div className="w32px-s-i w64px-ipad-i w128px-lg-i jsc-s"></div>
						<Skeleton
							variant="rectangular"
							width="128px"
							height="128px"
						/>
						<div className=" small-text jsc-s">
							<Skeleton
								variant="rectangular"
								width="128px"
								height="30px"
							/>
						</div>
					</div>
				</div>
				<div className="df-s jcc-s h100px-s border-bottom">
					<div className="dg-s gg1-s jcfe-s acc-s p16 w40-s">
						<div className="smaller">
							<Skeleton
								variant="rectangular"
								width="50px"
								height="20px"
							/>
						</div>
						<div className="dg-s jsc-s fw500-s bigger">
							<Skeleton
								variant="rectangular"
								width="16px"
								height="16px"
							/>
						</div>
					</div>
					<div className="p16 w180px-s"></div>
					<div className="dg-s gg1-s jcfs-s acc-s p16 w40-s">
						<div className="smaller">
							<Skeleton
								variant="rectangular"
								width="50px"
								height="20px"
							/>
						</div>
						<div className="dg-s jsc-s fw500-s bigger">
							<Skeleton
								variant="rectangular"
								width="16px"
								height="16px"
							/>
						</div>
					</div>
				</div>
				<div className="df-s gg1 jcc-s h100px-s">
					<div className="dg-s p16 w40-s jcfe-s acc-s">
						<div className="fw500-s bigger">
							<Skeleton
								variant="rectangular"
								width="50px"
								height="20px"
							/>
						</div>
					</div>
					<div className="dg-s jcc-s p16 smaller w180px-s acc-s">
						<Skeleton
							variant="rectangular"
							width="30px"
							height="20px"
						/>
					</div>
					<div className="dg-s p16 w40-s jcfs-s acc-s">
						<div className="fw500-s bigger">
							<Skeleton
								variant="rectangular"
								width="50px"
								height="20px"
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PreviousTeamSkeleton;
