import { useContext, useEffect, useState } from "react";
import LanguageContext from "../context/LanguageContext";

const useFormFieldValidation = () => {
	const initialValues = {
		name: "",
		email: "",
		message: "",
		surname: "",
	};

	const initialErrors = {
		name: "",
		email: "",
		message: "",
	};

	const [formErrors, setFormErrors] = useState(initialErrors);
	const [formData, setFormData] = useState(initialValues);

	const { textTranslate } = useContext(LanguageContext);

	const { FormText } = textTranslate?.Contact;

	const validateField = (inputName, value) => {
		let regex, errorMessage;

		if (inputName === "email") {
			regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
			errorMessage = FormText.ErrorEmail;
		} else if (inputName === "name") {
			regex =
				/^[a-zA-ZÀ-ÿñÑ'éèêçáéíóúàèìòùäëïöüâêîôûßæœøåāēīōū]{2,40}(?: [a-zA-ZÀ-ÿñÑ'éèêçáéíóúàèìòùäëïöüâêîôûßæœøåāēīōū]{2,40})*$/;
			errorMessage = FormText.ErrorName;
		} else if (inputName === "message") {
			regex = /^\S(?:.*\S)?(?:\r?\n\S(?:.*\S)?)*$/u;
			errorMessage = FormText.ErrorMng;
		}

		if (value.length === 0 || !regex.test(value)) {
			setFormErrors({
				...formErrors,
				[inputName]: errorMessage,
			});
		} else {
			setFormErrors({
				...formErrors,
				[inputName]: "",
			});
		}

		if (formData.hasOwnProperty(inputName)) {
			setFormData({
				...formData,
				[inputName]: value,
			});
		}
	};

	// Habilitar o deshabilitar el botón de envío
	const isSubmitDisabled = () => {
		//Comprobamos si hay algun error
		const hasErrors = Object.values(formErrors).every(
			(error) => error === ""
		);

		//quitamos el surname del array para que sirva de honeypot
		const formDataWithoutSurname = { ...formData };
		delete formDataWithoutSurname.surname;

		//Comprobamos si hay algun campo vacio en los input
		const hasData = Object.values(formDataWithoutSurname).every(
			(data) => data !== ""
		);

		return !hasErrors || !hasData;

		// if (!hasErrors || !hasData) {
		// 	return true;
		// } else {
		// 	return false;
		// }
	};

	// Actualizar los mensajes de error cuando cambia el idioma
	useEffect(() => {
		const updatedErrors = {};
		Object.keys(formErrors).forEach((key) => {
			if (formErrors[key]) {
				if (key === "email") {
					updatedErrors[key] = FormText.ErrorEmail;
				} else if (key === "name") {
					updatedErrors[key] = FormText.ErrorName;
				} else if (key === "message") {
					updatedErrors[key] = FormText.ErrorMng;
				}
			}
		});
		setFormErrors((prevErrors) => ({
			...prevErrors,
			...updatedErrors,
		}));
	}, [textTranslate]); // eslint-disable-line react-hooks/exhaustive-deps

	return {
		formErrors,
		setFormErrors,
		formData,
		setFormData,
		validateField,
		initialValues,
		initialErrors,
		isSubmitDisabled,
	};
};

export default useFormFieldValidation;
