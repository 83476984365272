import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { LOGO_NOT_FOUND, URL_IMG } from "../../../const/Const";
import RoundImage from "../../imagenesReact/RoundImage";

const LeadersSection = (props) => {
	const { idOrganization } = useParams();
	const handleErrorImg = (e) => {
		e.target.src = LOGO_NOT_FOUND;
		e.target.alt = "Logo not found";
	};

	return (
		<>
			<div className="position">{props.position}</div>
			<div className="contentImgPlayer">
				<RoundImage
					url={props.image}
					altImgText="Leeder image"
					leader={props.leader}
				/>
			</div>
			<div className="contentNameAndTeam">
				<div className="name ellipsis">{props.name}</div>
				<div className="teamlogo">
					<div className="w24px-s-i h24px-s-i">
						<img
							src={
								props.logourl === null || props.logourl === ""
									? LOGO_NOT_FOUND
									: `${URL_IMG}/organizations/${idOrganization}/teams/${props.teamshort}/${props.teamshort}.png`
							}
							alt={`Logo ${props.team}`}
							loading="lazy"
							className="w16px-s-i h16px-s-i"
							onError={handleErrorImg}
						/>
					</div>
					<div className="team ellipsis ">{props.team}</div>
				</div>
			</div>
			<div className="number">{props.value}</div>
		</>
	);
};

export default LeadersSection;
