import React from "react";

const TitleSubSection = ({ title, underline, sizeText }) => {
	return (
		<div
			className={
				underline === "true"
					? "titleSubSection"
					: "titleSubSection titleSubSection--freeBorder"
			}
		>
			{
				<h3
					className={
						sizeText
							? `titleSubSection__text titleSubSection__text--${sizeText} `
							: "titleSubSection__text titleSubSection__text--t2 "
					}
				>
					{title}
				</h3>
			}
		</div>
	);
};

export default TitleSubSection;
