import React, { useContext } from "react";
import { URL_IMG } from "../../../const/Const";
import useScreenSize from "../../../hook/useScreemSize";
import LanguageContext from "../../../context/LanguageContext";
import { Link } from "react-router-dom";
import "./styles/bannermain.scss";
import LogoTwitter from "../../img/LogoTwitter";
import Linkedln from "../../img/Linkedln";
import Facebook from "../../img/Facebook";
import Instagram from "../../img/Instagram";

// import { useLocation } from "react-router-dom";

const BannerMain = ({ contactBtnText }) => {
	// let location = useLocation();
	// let imgHome = true;
	// if (location.pathname !== "/") {
	// 	imgHome = false;
	// }

	const { togleLanguage } = useContext(LanguageContext);

	const { width } = useScreenSize();

	//Carga de imagenes para qeu el widget cargue las img estaticas correctamente
	const Banner = `/img/web/home/${
		togleLanguage ? "background_es" : "background_en"
	}${width <= 1024 ? "_mobile" : ""}-min.jpg`;

	return (
		<div className="mainBanner">
			<div className="bannerImg">
				<img
					className="w100-s"
					src={Banner}
					alt="Wheelchair"
					loading="lazy"
				/>
			</div>
			<div className="contentAllBtn">
				<Link className="BtnContact" to="/contact">
					<button className="buttonContact " type="submit">
						{contactBtnText}
					</button>
				</Link>
				<Link
					className="BtnFc"
					to="https://www.facebook.com/profile.php?id=61559062349074"
					target="_blank"
				>
					<Facebook />
				</Link>
				<Link
					className="BtnTw"
					to="https://twitter.com/quadstatistic"
					target="_blank"
				>
					<LogoTwitter />
				</Link>
				<Link
					className="BtnLk"
					to="https://www.linkedin.com/in/quadstatistics-wheelchair-stats-576111307/"
					target="_blank"
				>
					<Linkedln />
				</Link>
				<Link
					className="BtnIn"
					to="https://www.instagram.com/quadstatistics/"
					target="_blank"
				>
					<Instagram />
				</Link>
			</div>
		</div>
	);
};

export default BannerMain;
