import React, { Fragment } from "react";
import ScorePeriodPoints from "./ScorePeriodPoints";

const ScorePartialByPeriod = (props) => {
	return (
		<>
			<ul className={props.teamclassName}>
				<li className="name">{props.team}</li>
				<ScorePeriodPoints
					matchscore={props.matchscore}
					teamclassName={props.teamclass}
					periodplay={props.matchscore.periodplay}
					condition={props.condition}
				/>
				<li className="periodTotal">{props.scoreteam}</li>
			</ul>
		</>
	);
};

export default ScorePartialByPeriod;
