import { setExpiredTimeStorage, setSessionStorage } from "./StorageControl";

const SetItemResponse = (
	responseItem,
	storageItem,
	numberExpired,
	timeExpired
) => {
	window.sessionStorage.setItem(storageItem, JSON.stringify(responseItem));
	setSessionStorage(
		storageItem,
		setExpiredTimeStorage(numberExpired, timeExpired)
	);
};

export default SetItemResponse;
