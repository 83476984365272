import { jwtDecode } from "jwt-decode";

const userSessionVerify = (value) => {
	if (value) {
		const utj_decoded = jwtDecode(value);
		if (utj_decoded.exp * 1000 < Date.now()) {
			localStorage.removeItem("usrd");
			localStorage.removeItem("usrt");
			return false;
		} else {
			return true;
		}
	} else {
		return false;
	}
};

const userSession = (Token) => {
	if (userSessionVerify(Token)) {
		return "isLogged";
	} else {
		return "notLogged";
	}
};

export default userSession;
