import React, { useContext } from "react";
import { Link } from "react-router-dom";
import LanguageContext from "../../context/LanguageContext";
import ImgFlag from "./ImgFlag";
import "./styles/style.scss";

const prueba = () => {};

const LanguageButton = ({ handleLanguageButton }) => {
	const { textTranslate } = useContext(LanguageContext);

	return (
		<>
			<Link onClick={handleLanguageButton}>
				{" "}
				<ImgFlag
					language={textTranslate.LanguageBtnText}
					country={textTranslate.Flag}
				/>
			</Link>
		</>
	);
};

export { LanguageButton, prueba };
