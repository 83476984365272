import React, { createContext, useState, useMemo } from "react";

const DashContext = createContext({});

export function DashContextProvider({ children }) {
	//variable que almacena todos loa codes seleccionado
	const [allAuthorizateMatch, setAllAuthorizeMatch] = useState(() =>
		JSON.parse(window.sessionStorage.getItem("allauthorizematch"))
	);

	const [allUserEdit, setAllUserEdit] = useState(
		{}
		// () =>
		// JSON.parse(window.sessionStorage.getItem("allUseredit"))
	);

	const [userEdit, setUserEdit] = useState({});

	const [errorSelect, setErrorSelect] = useState(false);

	const [emailUserModalError, setEmailUserModalError] = useState("");

	const [nameUserModalError, setNameUserModalError] = useState("");

	const [roleUserModalError, setRoleUserModalError] = useState("");

	const value = useMemo(
		() => ({
			allAuthorizateMatch,
			setAllAuthorizeMatch,
			errorSelect,
			setErrorSelect,
			allUserEdit,
			setAllUserEdit,
			userEdit,
			setUserEdit,
			emailUserModalError,
			setEmailUserModalError,
			nameUserModalError,
			setNameUserModalError,
			roleUserModalError,
			setRoleUserModalError,
		}),
		[
			allAuthorizateMatch,
			errorSelect,
			allUserEdit,
			userEdit,
			emailUserModalError,
			nameUserModalError,
			roleUserModalError,
		]
	);

	return (
		<DashContext.Provider value={value}>{children}</DashContext.Provider>
	);
}

export default DashContext;
