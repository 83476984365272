import React from "react";
import { useParams } from "react-router-dom";
import { LOGO_NOT_FOUND, URL_IMG } from "../../const/Const";
import TitleSubSection from "../titlecomponent/TitleSubSection";

const LeagueItem = ({ leagueranking, group, groupsnumber, textTranslate }) => {
	const { idOrganization } = useParams();
	const filterGroup = leagueranking.filter(
		(team) => team.group === parseInt(group) // eslint-disable-next-line
	);

	const orderGroup = filterGroup.sort(function (a, b) {
		return a.order - b.order;
	});

	const handleErrorImg = (e) => {
		e.target.src = LOGO_NOT_FOUND;
		e.target.alt = "Logo not found";
	};

	return (
		<>
			{groupsnumber > 1 && (
				<TitleSubSection title={`${textTranslate.SubTitle} ${group}`} />
			)}
			<div className="w100-s pb64-s">
				<div className="w100-s">
					<div className="contentLeagueRanking center-cross">
						<table className="tableLeagueRanking">
							<tbody width="100%">
								<tr className="headTableRow">
									<th className="hiddenCell"></th>
									<th className="text-left-s">
										<div className="pr16-s">
											{textTranslate.StandingsTitle}
										</div>
									</th>
									<th
										className="text-center-s w22px-s w25px-fold w35px-m"
										title="Partidos jugados"
									>
										<div>PJ</div>
									</th>
									<th
										className="text-center-s w22px-s w25px-fold w35px-m"
										title="Victorias"
									>
										<div>V</div>
									</th>
									<th
										className="text-center-s w22px-s w25px-fold w35px-m"
										title="Derrotas"
									>
										<div>D</div>
									</th>
									<th
										className="text-center-s w22px-s w25px-fold w35px-m"
										title="Goles a favor"
									>
										<div>GF</div>
									</th>
									<th
										className="text-center-s w22px-s w25px-fold w35px-m"
										title="Goles en contra"
									>
										<div>GC</div>
									</th>
									<th
										className="text-center-s w22px-s w25px-fold w35px-m"
										title="Diferencia de goles"
									>
										<div>DG</div>
									</th>
									<th
										className="text-center-s w22px-s w25px-fold w35px-m"
										title="Puntos"
									>
										<div>PTS</div>
									</th>
								</tr>
								{orderGroup?.map((item) => (
									<React.Fragment key={item.teamcode}>
										<tr>
											<td className="hiddenCell"></td>
											<td className="">
												<div className="clubContent">
													<div className="posText textLeagueRanking">
														{item.order}
													</div>
													<img
														className=" w16px-s-i w24px-lm-i"
														alt={item.name}
														loading="lazy"
														onError={handleErrorImg}
														src={
															item.logourl ===
																null || ""
																? LOGO_NOT_FOUND
																: `${URL_IMG}/organizations/${idOrganization}/teams/${item.nameshort}/${item.logourl}`
														}
													/>
													<div className="ellipsis small-text is-none-to-lm">
														{item.team}
													</div>
													<div className="ellipsis small-text is-none-from-lm">
														{item.name}
													</div>
												</div>
											</td>
											<td className="textLeagueRanking">
												<span className="">
													{item.played}
												</span>
											</td>
											<td className="textLeagueRanking">
												{item.won}
											</td>
											<td className="textLeagueRanking">
												{item.lost}
											</td>
											<td className="textLeagueRanking">
												{item.goals_for}
											</td>
											<td className="textLeagueRanking">
												{item.goals_against}
											</td>
											<td className="textLeagueRanking">
												{item.goals_difference}
											</td>
											<td className="textLeagueRanking textLeagueRanking--highlights">
												{item.points}
											</td>
										</tr>
									</React.Fragment>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
};

export default LeagueItem;
