import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import TitleSection from "../components/titlecomponent/TitleSection";
import { ObjectToUrlParam } from "../components/utilities/ObjectToUrlParam";
import { WEB_ALL } from "../const/Const";
import GlobalContext from "../context/GlobalContext";
import useCss from "../hook/useGetCss";
import useRequest from "../hook/useRequest";
import useUpdateState from "../hook/useUpdateState";
import useGetPathBreadcrumbs from "../components/breadcrumb/hook/useGetPathBreadcrumbs";
import LanguageContext from "../context/LanguageContext";
import { LanguageButton } from "../components/language/LanguageButton";
import SkeletonItemChampions from "../components/cardshow/ChampionShow/skeleton/SkeletonItemChampions";
import ItemChampions from "../components/cardshow/ChampionShow/ItemChampions";

const Champions = () => {
	// console.log("render Champions");

	const { orderPage, idOrganization, pageRequest, seasonsCode } = useParams();

	const { getAllData } = useRequest();

	const { constructorCrumbs } = useGetPathBreadcrumbs();

	const { data, setData, setPreviousURLRef, setCurrentPageRef } =
		useContext(GlobalContext);

	const { textTranslate, handleLanguageButton } = useContext(LanguageContext);

	const { Title } = textTranslate.Champions;

	const { delStateStorage } = useUpdateState();

	const { getCssOrg } = useCss();

	const params = useParams();

	useEffect(() => {
		getCssOrg(pageRequest);
	}, [getCssOrg, pageRequest]);

	const filterorganizations = useCallback(() => {
		if (data) {
			return data.Organizations.find(
				(item) => item.id === parseInt(idOrganization)
			);
		}
	}, [data, idOrganization]);

	const dataBodyOptions = useMemo(() => {
		const items = {
			organization_id: idOrganization,
			page_request: pageRequest,
			season_code: seasonsCode,
		};
		return items;
	}, [idOrganization, pageRequest, seasonsCode]);

	useEffect(() => {
		setData(null);
		delStateStorage(["data"]);

		getAllData(
			WEB_ALL + "?" + ObjectToUrlParam(dataBodyOptions),
			"GET",
			null,
			null
		);
	}, [idOrganization, pageRequest, seasonsCode]); // eslint-disable-line react-hooks/exhaustive-deps

	const hadleClickItemChampions = useCallback(() => {
		setPreviousURLRef(2);
		window.sessionStorage.setItem("previousURLRef", JSON.stringify(2));
		setCurrentPageRef(3);
		window.sessionStorage.setItem("currentPageRef", JSON.stringify(3));
	}, [setPreviousURLRef, setCurrentPageRef]);

	const breadcrumbsList = useMemo(() => {
		return (
			<Breadcrumb
				dataPath={constructorCrumbs(
					params,
					parseInt(orderPage),
					parseInt(pageRequest)
				)}
			/>
		);
	}, [constructorCrumbs, orderPage, params, pageRequest]);

	return (
		<>
			<div className="secondHeader">
				<div className="contentLogin"></div>
				<div className="contentBtnLanguage">
					<LanguageButton
						handleLanguageButton={handleLanguageButton}
					/>
				</div>
			</div>
			<div className="main">
				<div className="contentHome">
					{breadcrumbsList}
					<div className="banner-content w100-s">
						<TitleSection title={Title} />
						{!data?.Championships && (
							<div className="payload w100-s center-cross">
								<SkeletonItemChampions />
							</div>
						)}
						{data?.Championships && (
							<ul className="listChampions">
								{data.Championships?.map((item) => (
									<ItemChampions
										code={item.code}
										namechampionship={item.namechampionship}
										name={filterorganizations().name}
										key={item.id}
										hadleClickItemChampions={
											hadleClickItemChampions
										}
										nextOrderPage={3}
									/>
								))}
							</ul>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Champions;
