import React from "react";
import { useParams } from "react-router-dom";
import { LOGO_NOT_FOUND, URL_IMG } from "../../../const/Const";

const VisitorCardWithColor = ({ colourteam, teamname, logo, teamshort }) => {
	const { idOrganization } = useParams();

	const stylecss = {
		color: {
			backgroundColor: "#" + colourteam,
		},
	};
		const handleErrorImg = (e) => {
			e.target.src = LOGO_NOT_FOUND;
			e.target.alt = "Logo not found";
		};
	return (
		<>
			<div className="card card--right">
				<div
					className="card__color card__color--right"
					style={stylecss.color}
				></div>
				<div className="card__name card__name--right is-none-to-lg">
					{teamname === null || "" ? "VISITOR" : teamname}
				</div>
				<div className="card__name card__name--right is-none-from-lg">
					{teamshort === null || "" ? "VISITOR" : teamshort}
				</div>
				<div className="card__logo card__logo--right">
					<img
						className="w16px-s-i w48px-ipad-i"
						src={
							logo === null || ""
								? LOGO_NOT_FOUND
								: `${URL_IMG}/organizations/${idOrganization}/teams/${teamshort}/${logo}`
						}
						alt={`Logo ${teamname}`}
						loading="lazy"
						onError={handleErrorImg}
					/>
				</div>
			</div>
		</>
	);
};

export default VisitorCardWithColor;
