import React from "react";
import { Navigate } from "react-router-dom";
import useUser from "../hook/useUser";

const PrivateRoute = ({ children }) => {
	const { isStateUser } = useUser();

	return isStateUser === "isLogged" ? children : <Navigate to="/login" />;
};
export default PrivateRoute;
