import React from "react";
import { Link, useParams } from "react-router-dom";
import { OUTBOUND_WEB, STATIC_IMG } from "../../../const/Const";

const ItemChampions = ({
	code,
	namechampionship,
	name,
	hadleClickItemChampions,
	nextOrderPage,
}) => {
	const { idOrganization, pageRequest, seasonsCode } = useParams();

	//Carga de imagenes para qeu el widget cargue las img estaticas correctameente
	const LogoOrg =
		OUTBOUND_WEB === "widget"
			? `${STATIC_IMG}/web/logo/organizations/${name.toLowerCase()}.png`
			: `/img/web/logo/organizations/${name.toLowerCase()}.png`;

	return (
		<>
			<li className="itemChampions">
				<div className="item-champion-container">
					<Link
						to={`/schedule/${nextOrderPage}/${pageRequest}/${idOrganization}/${seasonsCode}/${code}`}
						className="item-champion-link"
						onClick={hadleClickItemChampions}
					>
						<img
							src={LogoOrg}
							alt={`Logo ${name}`}
							loading="lazy"
						/>
						<p className="name-championsship smaller">
							{namechampionship}
						</p>
						<h3 className="name-country">{name}</h3>
					</Link>
				</div>
			</li>
		</>
	);
};

export default ItemChampions;
