import * as React from "react";

const EyeHide = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 415.59 268.3"
		style={{
			enableBackground: "new 0 0 415.59 268.3",
		}}
		xmlSpace="preserve"
		{...props}
	>
		<path d="M142.81 228.21c1.61-1.71 2.79-3.04 4.05-4.29 7.54-7.54 15.19-14.96 22.57-22.65 2.26-2.36 3.99-2.39 6.83-1.12 30.98 13.8 64.46 7.06 86.32-18.46 19.94-23.27 23.09-49.8 11.49-77.98-1.61-3.92-1.32-6.03 1.66-8.92 11.82-11.49 23.42-23.22 34.97-34.99 2.29-2.33 3.99-3.06 7.21-1.32 35.19 18.98 66.35 43.19 94.26 71.72 2.42 2.47 2.95 4.05.19 6.86-34.17 34.84-72.69 63.5-117.66 82.94-31.45 13.6-64.21 20.81-98.67 18.78-16.52-.97-32.68-4.09-48.55-8.7-1.4-.38-2.7-1.06-4.67-1.87zM264.99 37.22c-.68.87-1.11 1.56-1.67 2.12-7.89 7.9-15.91 15.67-23.63 23.72-2.48 2.59-4.56 2.72-7.82 1.63-25.41-8.44-49.08-4.86-70.19 11.74-24.48 19.25-33.73 51.4-23.6 80.92 1.48 4.3.81 6.86-2.37 9.95-12.78 12.42-25.35 25.06-37.84 37.78-2.31 2.36-3.96 2.4-6.74.82-32.41-18.35-61.51-40.95-87.48-67.61-4.48-4.6-5.27-3.77.15-9.27 34.62-35.14 73.76-63.79 119.45-82.94 31.34-13.14 63.96-19.91 97.97-17.09 13.69 1.14 27.19 4.58 40.77 7.02.92.17 1.78.71 3 1.21zM336.92 15c-.45 4.84-2.56 8.47-5.75 11.66-12.14 12.12-24.25 24.28-36.37 36.42L154.58 203.45c-20.02 20.03-40 40.1-60.08 60.07-8.3 8.26-21.57 5.41-25.1-5.38-2.06-6.3-.23-11.76 4.44-16.4 9.1-9.04 18.14-18.14 27.21-27.22 50.27-50.33 100.54-100.66 150.82-150.98 19.43-19.45 38.83-38.92 58.32-58.3 7.87-7.83 18.99-6.73 24.15 2.36 1.27 2.23 1.74 4.92 2.58 7.4z" />
		<path d="m190.01 182.1 65.73-65.52c6.59 12.66 4.41 37.54-11.47 53.65-15.84 16.06-40.88 18.83-54.26 11.87zM156.89 145.64c-3.32-17.86.04-33.03 11.45-45.94 13.31-15.05 30.16-20.15 50.39-16.63-2.21 3.61-4.16 5.43-8.51 4.79-12.96-1.9-22.91 7.93-21.22 20.92.45 3.46-.45 5.54-2.74 7.79-8.31 8.15-16.48 16.44-24.72 24.67-1.25 1.26-2.58 2.44-4.65 4.4z" />
	</svg>
);

export default EyeHide;
