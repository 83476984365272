import React from "react";
import LocalCard from "../../card/LocalCard";
import StatisticsHeaderPlayer from "./StatisticsHeaderPlayer/StatisticsHeaderPlayer";
import StatisticMainPlayer from "./StatisticMainPlayer/StatisticMainPlayer";
import StatisticsHeaderTeam from "./StatisticsHeaderTeam/StatisticsHeaderTeam";
import StatisticsMainTeam from "./StatisticsMainTeam/StatisticsMainTeam";
// import TeamTechnical from "./TeamMainTechnical/TeamMainTechnical";
import TeamMainTechnical from "./TeamMainTechnical/TeamMainTechnical";
import StatisticsHeaderTecnichal from "./StatisticHeaderTechnichal/StatisticHeadTechnichal";
import arrObjectConvert from "../../../utilities/arrObjectConvert";

const StatisticsTeam = ({
	logo,
	team,
	colour,
	state,
	ligthcolourteam,
	StatisticsByPlayerMatch,
	StatisticsByMatchTeam,
	TechnicalTeam,
	textTranslate,
	teamshort,
}) => {
	let courtPlayers;
	let benchPlayers;

	if (StatisticsByPlayerMatch) {
		courtPlayers = [
			StatisticsByPlayerMatch.filter((item) => item.court === 1),
		];
		benchPlayers = [
			StatisticsByPlayerMatch.filter((item) => item.court === 0),
		];
	}

	return (
		<>
			<div className="statistics__home">
				<LocalCard
					logo1={logo}
					teams1={team}
					teamshort={teamshort}
					shortvalue={false}
				/>
				{state === "LIVE" ? (
					<>
						{StatisticsByPlayerMatch && (
							<>
								<StatisticsHeaderPlayer
									title={textTranslate.Players.Title.Court}
									textTranslate={textTranslate}
								/>
								<StatisticMainPlayer
									players={arrObjectConvert(courtPlayers)}
									colour={colour}
									ligthcolour={ligthcolourteam}
									textTranslate={textTranslate}
									teamshort={teamshort}
								/>
								<StatisticsHeaderPlayer
									title={textTranslate.Players.Title.Bench}
									textTranslate={textTranslate}
								/>
								<StatisticMainPlayer
									players={arrObjectConvert(benchPlayers)}
									colour={colour}
									ligthcolour={ligthcolourteam}
									textTranslate={textTranslate}
									teamshort={teamshort}
								/>
							</>
						)}
						{StatisticsByMatchTeam && (
							<>
								<StatisticsHeaderTeam
									textTranslate={textTranslate}
								/>
								<StatisticsMainTeam
									teamStatistic={StatisticsByMatchTeam}
									textTranslate={textTranslate}
								/>
							</>
						)}
						{TechnicalTeam && (
							<>
								<StatisticsHeaderTecnichal
									title={textTranslate.Staff}
									textTranslate={textTranslate}
								/>
								<TeamMainTechnical
									teamTechnical={TechnicalTeam}
									textTranslate={textTranslate}
									colour={colour}
									ligthcolour={ligthcolourteam}
									teamshort={teamshort}
								/>
							</>
						)}
					</>
				) : (
					<>
						{StatisticsByPlayerMatch && (
							<>
								<StatisticsHeaderPlayer
									title={textTranslate.Players.Title.All}
									textTranslate={textTranslate}
								/>
								<StatisticMainPlayer
									players={StatisticsByPlayerMatch}
									colour={colour}
									ligthcolour={ligthcolourteam}
									textTranslate={textTranslate}
									teamshort={teamshort}
								/>
							</>
						)}
						{StatisticsByMatchTeam && (
							<>
								<StatisticsHeaderTeam
									textTranslate={textTranslate}
								/>
								<StatisticsMainTeam
									teamStatistic={StatisticsByMatchTeam}
									textTranslate={textTranslate}
								/>
							</>
						)}
						{TechnicalTeam && (
							<>
								<StatisticsHeaderTecnichal
									title={textTranslate.Staff}
									textTranslate={textTranslate}
								/>
								<TeamMainTechnical
									teamTechnical={TechnicalTeam}
									textTranslate={textTranslate}
									colour={colour}
									ligthcolour={ligthcolourteam}
									teamshort={teamshort}
								/>
							</>
						)}
					</>
				)}
			</div>
		</>
	);
};

export default StatisticsTeam;
