import React from "react";

const StatisticsMainTeam = ({ teamStatistic, textTranslate }) => {
	return (
		<>
			<div className="teamStatistics">
				<ul className="team">
					<li className="team__item">
						<div className="teamcard">
							<p className="teamcard__name">
								{textTranslate.Team.BodyTable.ActionTeam}
							</p>
						</div>
					</li>
					<li className="team__item">
						<div className="thead_mobile">
							{textTranslate.Team.HeadTable.fourkey}
						</div>
						<p>{teamStatistic.fourkey}</p>
					</li>
					<li className="team__item">
						<div className="thead_mobile">
							{textTranslate.Team.HeadTable.tenkey}
						</div>
						<p>{teamStatistic.tenkey}</p>
					</li>
					<li className="team__item">
						<div className="thead_mobile">
							{textTranslate.Team.HeadTable.twelvesecond}
						</div>
						<p>{teamStatistic.twelveseconds}</p>
					</li>
					<li className="team__item">
						<div className="thead_mobile">
							{textTranslate.Team.HeadTable.throwin}
						</div>
						<p>{teamStatistic.throwin}</p>
					</li>
					<li className="team__item">
						<div className="thead_mobile">
							{textTranslate.Team.HeadTable.fortypos}
						</div>
						<p>{teamStatistic.fortyseconds}</p>
					</li>
					<li className="team__item">
						<div className="thead_mobile">
							{textTranslate.Team.HeadTable.nodrib}
						</div>
						<p>{teamStatistic.nodribling}</p>
					</li>
				</ul>
			</div>
		</>
	);
};

export default StatisticsMainTeam;
