import React from "react";
import StateViewColor from "../../utilities/StateViewColor";

const MatchData = ({
	data,
	time,
	state,
	textStateTranslate,
	scoreteam1,
	scoreteam2,
	period,
	textPeriodTranslate,
	city,
	hour,
}) => {
	return (
		<div className="match__data">
			<div className="data">
				<div className="data__contenthour">
					<div className={"data__hour"}>{city}</div>
				</div>
				<div
					className={
						state === "PREVIUS" || state === "FINISH"
							? "data__contenthour"
							: "dn-s"
					}
				>
					<div className={"data__hour"}>{data}</div>
				</div>
				<div
					className={
						state === "PREVIUS" || state === "FINISH"
							? "data__contenthour"
							: "dn-s"
					}
				>
					<div className="data__hour">{time}</div>
				</div>
				<div
					className={
						state === "FINISH" || state === "LIVE"
							? "data__contenthour"
							: "dn-s"
					}
				>
					<div className={"data__hour bold fw600-s big-text"}>
						{scoreteam1 + " - " + scoreteam2}
					</div>
				</div>
				<div className={state === "LIVE" ? "data__hour" : "dn-s"}>
					{textPeriodTranslate + " " + period}
				</div>
				<StateViewColor
					state={state}
					textStateTranslate={textStateTranslate}
					position="data"
				/>
			</div>
		</div>
	);
};

export default MatchData;
