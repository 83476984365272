import * as React from "react";
const Linkedln = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		id="Capa_1"
		x={0}
		y={0}
		style={{
			enableBackground: "new 0 0 60.59 62",
		}}
		viewBox="0 0 60.59 62"
		{...props}
	>
		<style>{".st1{display:inline}.st1,.st2{fill:#fff}"}</style>
		<path
			d="M1 20.84h12.57v40.38H1V20.84zM7.29.78c4.02 0 7.28 3.26 7.28 7.27a7.28 7.28 0 0 1-7.28 7.28C3.26 15.33 0 12.07 0 8.05 0 4.04 3.26.78 7.29.78M21.45 20.84h12.04v5.52h.16c1.68-3.18 5.77-6.52 11.89-6.52 12.71 0 15.05 8.36 15.05 19.23v22.15H48.05V41.59c0-4.69-.09-10.71-6.52-10.71-6.53 0-7.53 5.1-7.53 10.37v19.97H21.45V20.84z"
			className="st2"
		/>
	</svg>
);
export default Linkedln;
