import { useContext } from "react";
import GlobalContext from "../context/GlobalContext";

const useUpdateState = () => {
	const {setData } = useContext(GlobalContext);

	const delStateStorage = (elements) => {
		elements.forEach((item) => {
			window.sessionStorage.removeItem(item);
			switch (item) {
				case "data":
					setData(null);
					break;
				default:
					break;
			}
		});
	};

	return { delStateStorage };
};

export default useUpdateState;
