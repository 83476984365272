import React from "react";

const ScoreChrone = ({ periodplay, time, textPeriodranslate }) => {
	return (
		<div className="time">
			<div className="nameLabel">
				{periodplay <= 4
					? `${textPeriodranslate.Period} ${periodplay}`
					: textPeriodranslate.Period}
			</div>
			<div className="timeLabel">{time}</div>
		</div>
	);
};

export default ScoreChrone;
