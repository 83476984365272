import React, { useState } from "react";
import LocalCard from "../card/LocalCard";
import VisitorSpecialCard from "../card/VisitorSpecialCard";
import MatchData from "./MatchData";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/es";
import Modal from "../../modals/modal/Modal";
import TitleSubSection from "../../titlecomponent/TitleSubSection";

const ScheduleLeagueItemMatchPlayoff = ({
	code,
	matchdays,
	typeMatch,
	matchList,
	nextOrderPage,
	textTranslate,
	stateTextTranslate,
	modalTextTranslate,
	setdata,
	delstatestorage,
}) => {
	const { idOrganization, pageRequest, seasonsCode, championsCode } =
		useParams();

	const [isModal, setModalShow] = useState(false);

	const filterMatchdays = matchList.filter(
		(matchList) =>
			matchList.leaguematchday === parseInt(matchdays) &&
			matchList.matchtype === parseInt(typeMatch) // eslint-disable-next-line
	);

	return (
		<>
			{isModal && (
				<>
					<Modal
						elementById="modal"
						isModal={isModal}
						setModalShow={setModalShow}
					>
						<div>{modalTextTranslate.Previus}</div>
					</Modal>
				</>
			)}
			{filterMatchdays?.map((matchList) => (
				<>
					{matchList.state === "PREVIUS" ? (
						<div className="matchList pb32-s">
							<TitleSubSection
								title={`${
									textTranslate?.SubTitle[
										`${matchList.matchtypetext}`
									]
								}`}
								underline={"false"}
								key={matchList.code}
							/>
							<ul
								className="matchList__match"
								key={matchList.code}
							>
								<li className="ptb32-s">
									<Link
										// to={"#"}
										to={`/previous/${nextOrderPage}/${pageRequest}/${idOrganization}/${seasonsCode}/${championsCode}/${matchList.teamlocalcode}/${matchList.teamvisitorcode}`}
										className="match"
										// onClick={() => {
										// 	setModalShow(true);
										// }}
									>
										<div className="match__team">
											<LocalCard
												teams1={matchList.nameteam1}
												logo1={matchList.logoteam1}
												teamshort={
													matchList.localshortname
												}
												shortvalue={true}
											/>
										</div>
										<MatchData
											data={moment(matchList.date).format(
												"l"
											)}
											time={
												moment(matchList.hour).format(
													"HH:mm"
												) + " h"
											}
											scoreteam1={matchList.scoreteam1}
											scoreteam2={matchList.scoreteam2}
											state={matchList.state}
											textStateTranslate={
												stateTextTranslate[
													matchList.state
												]
											}
											period={matchList.period}
											textPeriodTranslate={
												stateTextTranslate.Period
											}
											city={matchList.city}
										/>
										<div className="match__team match__team--right">
											<VisitorSpecialCard
												teams2={matchList.nameteam2}
												logo2={matchList.logoteam2}
												teamshort={
													matchList.visitorshortname
												}
												shortvalue={true}
											/>
										</div>
									</Link>
								</li>
							</ul>
						</div>
					) : (
						<div className="matchList pb32-s">
							<TitleSubSection
								title={`${
									textTranslate?.SubTitle[
										`${matchList.matchtypetext}`
									]
								}`}
								underline={"false"}
								key={matchList.code}
							/>
							<ul
								className="matchList__match"
								key={matchList.code}
							>
								<li className="ptb32-s">
									<Link
										to={`/match/${nextOrderPage}/${pageRequest}/${idOrganization}/${seasonsCode}/${championsCode}/${matchList.code}`}
										className="match"
										onClick={() => {
											setdata(null);
											delstatestorage(["data"]);
										}}
									>
										<div className="match__team">
											<LocalCard
												teams1={matchList.nameteam1}
												logo1={matchList.logoteam1}
												teamshort={
													matchList.localshortname
												}
												shortvalue={true}
											/>
										</div>
										<MatchData
											data={moment(matchList.date).format(
												"l"
											)}
											time={
												moment(matchList.hour).format(
													"HH:mm"
												) + " h"
											}
											scoreteam1={matchList.scoreteam1}
											scoreteam2={matchList.scoreteam2}
											state={matchList.state}
											textStateTranslate={
												stateTextTranslate[
													matchList.state
												]
											}
											period={matchList.period}
											textPeriodTranslate={
												stateTextTranslate.Period
											}
											city={matchList.city}
										/>
										<div className="match__team match__team--right">
											<VisitorSpecialCard
												teams2={matchList.nameteam2}
												logo2={matchList.logoteam2}
												teamshort={
													matchList.visitorshortname
												}
												shortvalue={true}
											/>
										</div>
									</Link>
								</li>
							</ul>
						</div>
					)}
				</>
			))}
		</>
	);
};

export default ScheduleLeagueItemMatchPlayoff;
