import * as React from "react";
const LogoTwitter = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		id="Capa_1"
		x={0}
		y={0}
		style={{
			enableBackground: "new 0 0 60.59 62",
		}}
		viewBox="0 0 60.59 62"
		{...props}
	>
		<style>{".st0{fill:#fff}"}</style>
		<path
			d="m56.24 55.68-20.3-29.49-2.3-3.34-14.52-21.1L17.91 0H.07l4.35 6.32 19.31 28.06 2.3 3.33 15.51 22.54 1.2 1.75h17.84l-4.34-6.32zm-11.37 2.28L28.75 34.54l-2.3-3.33L7.75 4.04h8.05l15.13 21.98 2.3 3.33 19.7 28.62h-8.06z"
			className="st0"
		/>
		<path
			d="m26.45 31.21 2.3 3.33-2.72 3.17L5.15 62H0l23.73-27.62zM58.44 0l-22.5 26.18-2.73 3.17-2.29-3.34 2.72-3.16L48.89 5.1l4.4-5.1z"
			className="st0"
		/>
	</svg>
);
export default LogoTwitter;
