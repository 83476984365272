import * as React from "react";

const Logout = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 462.79 463.52"
		style={{
			enableBackground: "new 0 0 462.79 463.52",
		}}
		xmlSpace="preserve"
		{...props}
	>
		<path d="M168.35 127.94h-39.91c0-15.99-.98-31.7.26-47.24 1.47-18.4 17.53-31.88 36.27-31.96 33.33-.16 66.66-.05 99.98-.05h6.87V0c7.46 2.25 14.22 4.25 20.96 6.31 47.42 14.48 94.83 28.98 142.25 43.45 16.65 5.08 26.45 17.3 27.6 34.62.17 2.49.15 5 .15 7.49.01 110.32.01 220.63.01 330.95 0 19.12-8.56 32.82-24.52 38.35-4.32 1.5-9.12 2.23-13.69 2.23-85.99.13-171.97.11-257.96.08-22.62-.01-38.33-15.75-38.4-38.34-.04-13.49-.01-26.98-.01-40.88h40.05v38.53h220.83c.1-.23.21-.46.31-.69-5.96-2.45-11.9-4.94-17.87-7.35-31.64-12.75-63.28-25.53-94.97-38.16-3.42-1.36-4.66-3.1-4.66-6.92.12-90.65.11-181.3.06-271.96-.01-10.24 1.4-8.81-8.61-8.83-30-.06-59.99.05-89.99-.1-3.9-.02-5.2.95-5.1 4.99.27 11.31.09 22.61.09 34.17z" />
		<path d="M222.06 235.77v40.31H70.15c-.17.38-.33.76-.5 1.15 15.73 13.41 31.46 26.83 46.86 39.96-9.48 9.43-18.74 18.62-27.67 27.51-29.33-29.31-59.2-59.17-88.85-88.8 29.35-29.39 59.11-59.18 88.57-88.68 8.94 9.02 18.13 18.3 27.71 27.98-12.79 12.48-26.41 25.78-40.04 39.08l.87 1.5c48.18-.01 96.33-.01 144.96-.01z" />
	</svg>
);

export default Logout;
