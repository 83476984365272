
import React from "react";
import Skeleton from "../../../skeleton/Skeleton";

const SkeletonScoreBoard = () => {
	return (
		<>
			<div className="scoreboard h175px-s h142px-xm h154px-lg">
				<div className="scoreboard__home is-none-to-lg ">
					<div className="card">
						<div className="card__color w12px-s"></div>
						<div className="card__logo">
							<Skeleton variant="circular w32px-s-i w65px-ipad-i w90px-lg-i h32px-s h65px-ipad h90px-lg" />
						</div>
						<div className="card__vertical-line is-none-to-lg"></div>
						<div className="card__name normal">
							<Skeleton
								variant="rectangular"
								width="100px"
								height="32px"
							/>
						</div>
					</div>
				</div>
				<div className="scoreboard__points">
					<div className="points">
						<div className="points__home h175px-s h142px-xm h154px-lg">
							<div className="points__cardmini is-none-from-lg ">
								<div className="card mb8-s mb0-lg">
									<div className="card__logo">
										<Skeleton variant="circular w32px-s-i h32px-s" />
									</div>
									<div className="card__name normal">
										<Skeleton
											variant="rectangular"
											width="30px"
											height="32px"
										/>
									</div>
								</div>
							</div>
							<div className="number df-s jcc-s">
								<Skeleton
									variant="rectangular"
									width="60px"
									height="40px"
								/>
							</div>
						</div>
						<div className="points__general h175px-s h142px-xm h154px-lg">
							<div className="time df-s jcc-s">
								<Skeleton
									variant="rectangular"
									width="60px"
									height="37px"
								/>
							</div>
							<div className="period">
								<ul className="home">
									<Skeleton
										variant="rectangular"
										width="160px"
										height="12px"
									/>
								</ul>
								<ul className="visitor">
									<Skeleton
										variant="rectangular"
										width="160px"
										height="12px"
									/>
								</ul>
							</div>
							<div className="stadeMatch">
								<Skeleton
									variant="rectangular"
									width="60px"
									height="25px"
								/>
							</div>
						</div>
						<div className="points__visitor h175px-s h142px-xm h154px-lg">
							<div className="points__cardmini is-none-from-lg ">
								<div className="card mb8-s mb0-lg">
									<div className="card__logo">
										<Skeleton variant="circular w32px-s-i h32px-s" />
									</div>
									<div className="card__name normal">
										{" "}
										<Skeleton
											variant="rectangular"
											width="30px"
											height="32px"
										/>
									</div>
								</div>
							</div>
							<div className="number df-s jcc-s">
								<Skeleton
									variant="rectangular"
									width="60px"
									height="40px"
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="scoreboard__visitor is-none-to-lg ">
					<div className="card card--specialPos">
						<div className="card__color card__color--specialPos w12px-s"></div>
						<div className="card__logo card__logo--specialPos">
							<Skeleton variant="circular w32px-s-i w65px-ipad-i w90px-lg-i h32px-s h65px-ipad h90px-lg" />
						</div>
						<div className="card__vertical-line card__vertical-line--right is-none-to-lg"></div>
						<div className="card__name card__name--specialPos normal">
							<Skeleton
								variant="rectangular"
								width="100px"
								height="32px"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="referee df-s w30-lg w50-s jcc-s gg1-s">
				<div className="playercard__img acc-s dg-s">
					<Skeleton variant="circular w32px-s-i h32px-s" />
				</div>
				<div className="contentNameReferee">
					<div className="refereeName pt8-s">
						<Skeleton
							variant="rectangular"
							width="50px"
							height="10px"
						/>{" "}
					</div>
					<div className="refereeCategory supersmall pt4-s">
						<Skeleton
							variant="rectangular"
							width="30px"
							height="5px"
						/>{" "}
					</div>
				</div>
			</div>
			<div className="referee df-s w30-lg w50-s jcc-s gg1-s">
				<div className="playercard__img acc-s dg-s">
					<Skeleton variant="circular w32px-s-i h32px-s" />
				</div>
				<div className="contentNameReferee">
					<div className="refereeName pt8-s">
						<Skeleton
							variant="rectangular"
							width="50px"
							height="10px"
						/>{" "}
					</div>
					<div className="refereeCategory supersmall pt4-s">
						<Skeleton
							variant="rectangular"
							width="30px"
							height="5px"
						/>{" "}
					</div>
				</div>
			</div>
		</>
	);
};

export default SkeletonScoreBoard;
