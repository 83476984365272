import React from "react";
import LeadersCard from "./LeadersCard";

const LeaderGroup = ({ tries, recover, assist, textTranslate }) => {
	return (
		<>
			<div className="contentLeader">
				<LeadersCard
					title={textTranslate.Tries}
					data={tries}
					value="tries"
				/>
				<LeadersCard
					title={textTranslate.Recover}
					data={recover}
					value="recover"
				/>
				<LeadersCard
					title={textTranslate.Assists}
					data={assist}
					value="assists"
				/>
			</div>
		</>
	);
};

export default LeaderGroup;
