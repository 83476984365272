import React from "react";
import Skeleton from "../../../skeleton/Skeleton";

const SkeletonStadisticGlobal = () => {
	return (
		<>
			<div className="content-panel db-s w100-s ">
				<div className="statistics__home">
					<ul>
						<li className="p16-s">
							<Skeleton
								variant="rectangular w100-s "
								height="32px"
							/>
						</li>
						<li className="p16-s">
							<Skeleton
								variant="rectangular w100-s "
								height="32px"
							/>
						</li>
						<li className="p16-s">
							<Skeleton
								variant="rectangular w100-s "
								height="32px"
							/>
						</li>
						<li className="p16-s">
							<Skeleton
								variant="rectangular w100-s "
								height="32px"
							/>
						</li>
						<li className="p16-s">
							<Skeleton
								variant="rectangular w100-s "
								height="32px"
							/>
						</li>
						<li className="p16-s">
							<Skeleton
								variant="rectangular w100-s "
								height="32px"
							/>
						</li>
						<li className="p16-s">
							<Skeleton
								variant="rectangular w100-s "
								height="32px"
							/>
						</li>
					</ul>
				</div>
			</div>
		</>
	);
};

export default SkeletonStadisticGlobal;
