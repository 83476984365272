import * as React from "react";
const Instagram = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		id="Capa_1"
		x={0}
		y={0}
		style={{
			enableBackground: "new 0 0 60.59 62",
		}}
		viewBox="0 0 60.59 62"
		{...props}
	>
		<style>
			{
				".st1{display:inline;fill:#fff}.st3{display:none;fill:#783a4d}.st4{fill:#fff}"
			}
		</style>
		<path
			d="M36.95 61.01c-.73-.01-1.45-.01-2.18-.02h-8.35c-.73.01-1.45.01-2.18.02-.26-.01-.51-.01-.77-.02-2.18-.07-4.37-.05-6.55-.21-3.12-.23-6.12-.98-8.78-2.73-3.46-2.29-5.6-5.5-6.58-9.51-.37-1.55-.56-3.13-.63-4.72-.33-7.74-.2-15.49-.1-23.23.03-2.17.2-4.35.73-6.48.99-3.95 3.11-7.12 6.49-9.41 1.89-1.28 4.01-2.02 6.25-2.44 1.87-.35 3.77-.44 5.67-.5 6.48-.21 12.97-.1 19.45-.07 1.82.01 3.65.12 5.46.31 2.7.28 5.28 1.01 7.6 2.48 2.67 1.7 4.67 3.99 5.91 6.92.82 1.95 1.32 3.99 1.44 6.1.11 1.95.25 3.9.26 5.85.04 5.33.03 10.66 0 15.99a83.83 83.83 0 0 1-.17 5.01c-.19 2.83-.75 5.58-2.11 8.11-1.75 3.24-4.33 5.56-7.73 6.96-1.69.69-3.46 1.13-5.28 1.26-1.87.14-3.74.22-5.61.31-.74.02-1.49.01-2.24.02zm17.78-29.64-.15-12.11c-.02-1.29-.21-2.56-.5-3.8-.57-2.45-1.81-4.51-3.87-6.01-1.68-1.22-3.6-1.84-5.64-2.05-1.84-.19-3.69-.32-5.53-.32-6.84 0-13.69-.19-20.53.13-1.61.07-3.21.25-4.77.74C10 9.12 7.78 11.67 6.8 15.41c-.47 1.78-.56 3.6-.58 5.41-.09 7.67-.23 15.33.09 23 .05 1.18.2 2.35.49 3.49.95 3.72 3.2 6.23 6.87 7.44 1.72.57 3.51.71 5.3.79 2.13.1 4.27.15 6.4.14 4.66 0 9.31-.06 13.97-.08 1.88-.01 3.75-.08 5.61-.35 1.61-.23 3.16-.68 4.55-1.54 2.44-1.51 3.9-3.73 4.57-6.49.46-1.88.55-3.81.58-5.73.06-3.37.02-6.74.02-10.11"
			className="st4"
		/>
		<path
			d="M30.45 46.61c-8.33.02-15.28-6.74-15.28-15.25 0-8.42 6.71-15.27 15.25-15.28 8.52 0 15.27 6.77 15.28 15.25.02 8.64-7.01 15.29-15.25 15.28zm-.01-5.4c5.31.02 9.84-4.23 9.87-9.81.03-5.35-4.16-9.91-9.83-9.93-5.61-.02-9.88 4.4-9.91 9.85-.02 5.61 4.55 9.91 9.87 9.89zM49.9 15.48c-.02 2.01-1.64 3.62-3.63 3.61-1.85-.01-3.52-1.47-3.57-3.58-.05-1.95 1.62-3.65 3.63-3.61 2.2.04 3.55 1.82 3.57 3.58z"
			className="st4"
		/>
	</svg>
);
export default Instagram;
