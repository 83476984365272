import React, { useEffect, useState } from "react";
import TitleSection from "../../titlecomponent/TitleSection";
import ScheduleLeagueItemMatch from "./ScheduleLeagueItemMatch";

const ScheduleGroup = ({
	matchList,
	nextOrderPage,
	textTranslate,
	state,
	modal,
	setdata,
	delstatestorage,
}) => {
	const [numberMatchday, setNumberMatchday] = useState(null);

	useEffect(() => {
		let savematchdays = [];
		//recorremos todos los partidos para almacenar los leaguematchday
		matchList?.map(
			(matchday) =>
				(savematchdays = [...savematchdays, matchday.leaguematchday])
		);
		//eliminados los duplicados para guardar solo los dias diferentes
		setNumberMatchday([...new Set(savematchdays)]);
	}, [matchList]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{textTranslate && (
				<TitleSection
					title={textTranslate?.Title}
					subtitle={textTranslate?.SubTitle["Group stage"]}
					border={"false"}
				/>
			)}
			{numberMatchday?.map((item) => (
					<ScheduleLeagueItemMatch
						key={item}
						matchdays={item}
						matchList={matchList}
						nextOrderPage={nextOrderPage}
						matchdaysTextTranslate={textTranslate.Matchdays}
						stateTextTranslate={state}
						modalTextTranslate={modal}
						setdata={setdata}
						delstatestorage={delstatestorage}
					/>
				))}
		</>
	);
};

export default ScheduleGroup;
