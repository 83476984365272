import React from "react";
import LeaderGroup from "../importantAction/LeaderGroup";

const ScheduleStandout = ({ tries, recover, assist, textTranslate }) => {
	return (
		<>
			<LeaderGroup
				tries={tries}
				recover={recover}
				assist={assist}
				textTranslate={textTranslate}
			/>
		</>
	);
};

export default ScheduleStandout;
