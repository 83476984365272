export const setSessionStorage = (key, subkey = null, value) => {
	if (subkey != null) {
		let oldValueStoraga;

		if (JSON.parse(window.sessionStorage.getItem(key))) {
			oldValueStoraga = JSON.parse(window.sessionStorage.getItem(key));
		} else {
			oldValueStoraga = null;
		}

		const newValueStorage = { [subkey]: value };

		oldValueStoraga = { ...oldValueStoraga, ...newValueStorage };

		window.sessionStorage.setItem(key, JSON.stringify(oldValueStoraga));
	} else {
		window.sessionStorage.setItem(key, JSON.stringify(value));
	}
};

export const getSessionStorage = (key, subkey = null) => {
	const itemStorage = JSON.parse(sessionStorage.getItem(key));

	if (itemStorage != null || itemStorage !== "undefined") { // eslint-disable-next-line
		return itemStorage[subkey];
	} else {
		return itemStorage;
	}
};

export const delSessionStorageItem = (key, subkey = null) => {
	if (subkey !== null) {
		const itemStr = JSON.parse(sessionStorage.getItem(key));
		// // if the item doesn't exist, return null
		if (Object.keys(itemStr).length === 0) {
			window.sessionStorage.removeItem(key);
		}

		delete itemStr[subkey];
	} else {
		window.sessionStorage.removeItem(key);
	}
};

//opciones para la creacion de tiempo de cache
const toExpire = (action) => {
	switch (action) {
		case "day":
			return 24 * 60 * 60 * 1000;
		case "hour":
			return 60 * 60 * 1000;
		case "minute":
			return 60 * 1000;
		case "second":
			return 1000;
		default:
			break;
	}
};

//crea el tiempo de expiracion elegido entre las opciones del switch de la cache
export const setExpiredTimeStorage = (timeExpired, typeExpiered) => {
	let date;
	date = Date.now() + timeExpired * toExpire(typeExpiered);
	return date;
};

export const getExpiredTimeStorage = (key, value) => {
	let storageDate = getSessionStorage(key, value);
	if (Boolean(storageDate)) {
		if (storageDate <= Date.now()) {
			return false;
		} else {
			return true;
		}
	} else {
		return false;
	}
};
