import React, { createContext, useState, useMemo } from "react";
import userSession from "../components/utilities/UserSessionVerify";
import PropTypes from "prop-types";

const LoginContext = createContext({});

export function LoginContextProvider({ children }) {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [c_Password, setC_Password] = useState("");

	const [passwordShown, setPasswordShown] = useState(false);
	const [c_PasswordShown, setC_PasswordShown] = useState(false);

	const [emailError, setEmailError] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [c_PasswordError, setC_PasswordError] = useState("");

	const [rememberMe, setRememberMe] = useState(() =>
		JSON.parse(window.localStorage.getItem("remenber"))
	);

	const [userData, setUserData] = useState(() =>
		JSON.parse(window.sessionStorage.getItem("usrd"))
	);

	const [userToken, setUserToken] = useState(() =>
		JSON.parse(window.sessionStorage.getItem("usrt"))
	);

	const isAuth = userSession(userToken);

	const value = useMemo(
		() => ({
			email,
			setEmail,
			password,
			setPassword,
			c_Password,
			setC_Password,
			passwordShown,
			setPasswordShown,
			c_PasswordShown,
			setC_PasswordShown,
			emailError,
			setEmailError,
			passwordError,
			setPasswordError,
			c_PasswordError,
			setC_PasswordError,
			rememberMe,
			setRememberMe,
			userData,
			setUserData,
			userToken,
			setUserToken,
			isAuth,
		}),
		[
			email,
			password,
			c_Password,
			passwordShown,
			c_PasswordShown,
			emailError,
			passwordError,
			c_PasswordError,
			rememberMe,
			userData,
			userToken,
			isAuth,
		]
	);

	return (
		<LoginContext.Provider value={value}>{children}</LoginContext.Provider>
	);
}

LoginContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default LoginContext;
