import React from "react";
import Skeleton from "../../../skeleton/Skeleton";

const SkeletonItemChampions = () => {
	return (
		<>
			<ul className="listChampions">
				<li className="itemChampions">
					<div className="item-champion-container">
						<Skeleton
							variant="rectangular"
							width="100%"
							height="180px"
							className="item-champion-link"
						/>
					</div>
				</li>
				<li className="itemChampions">
					<div className="item-champion-container">
						<Skeleton
							variant="rectangular"
							width="100%"
							height="180px"
							className="item-champion-link"
						/>
					</div>
				</li>
				{/* <li className="itemChampions">
					<div className="item-champion-container">
						<Skeleton
							variant="rectangular"
							width="100%"
							height="180px"
							className="item-champion-link"
						/>
					</div>
				</li>{" "}
				<li className="itemChampions">
					<div className="item-champion-container">
						<Skeleton
							variant="rectangular"
							width="100%"
							height="180px"
							className="item-champion-link"
						/>
					</div>
				</li> */}
			</ul>
		</>
	);
};

export default SkeletonItemChampions;