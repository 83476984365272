// Entornos de trabajo de la API
const PRO = "https://quadstatistics20.clickerti.es/"; //Entorno de Producción
const PRE = "https://pre.quadstatistics20.clickerti.es/"; // Entorno de Pre-Producción
const DEV = "https://dev.quadstatistics20.clickerti.es/"; // Entorno de Pre-Producción
const LOCAL = "http://127.0.0.1:8000/"; // Entorno de desarrollo local

// Entornos de trabajo de la WEB
// const PRE_WEB = "https://pre.quadstatistics20.clickerti.es/"; // Entorno de Pre-Producción
// const LOCAL_WEB = "http://127.0.0.1:3000/";
const PRO_WEB = "https://quadstatistic.com/"; //Entorno de Producción
const DEV_WEB = "https://dev.quadrugbyweb.clickerti.es/"; // Entorno de Pre-Producción
const WIDGET = "https://quadwidget.clickerti.es/";

//----***************************************----//
//----***************************************----//
//----***************************************----//
//---- ** VARIABLES DE CONTROL DE DESTINO ** ----//

//Esta es la variabla que hay que cambiar para cambiar el entorno de la web
export const URL_DOMIAN = PRO; //PRO | PRE | DEV | LOCAL

// Esta es la varible que hay que cambiar para que el widget
// encuentre la home especifica para el widget
export const OUTBOUND_WEB = "web"; // web | widget

//Esta variable sirve para poder ver en el localhost el widget en
//proceso de desarrollo.Oor defecto siempre que no se este
// desarrollando el widget, tiene que estar en null
//durante el desarrollo se debe e poner el ie de organizacion
export const ORG_SELECT = null; // null | "1" | "2"

//----***************************************----//
//----***************************************----//
//----***************************************----//
//----***************************************----//

//URL principal
export const URL_BASIC_SERVER =
	URL_DOMIAN === LOCAL
		? URL_DOMIAN + "api/v1/"
		: URL_DOMIAN + "public/api/v1/";

//Imagenes
export const URL_IMG =
	URL_DOMIAN !== PRO ? DEV + "public/img" : PRO + "public/img";

//URL imagenes staticas widget
export const URL_IMG_STATITC_WIDGET =
	URL_DOMIAN !== PRO ? WIDGET + "img" : WIDGET + "img";

//URL imagenes staticas web
export const URL_IMG_STATITC_WEB =
	URL_DOMIAN !== PRO ? DEV_WEB + "img" : PRO_WEB + "img";

export const STATIC_IMG =
	OUTBOUND_WEB === "widget" ? URL_IMG_STATITC_WIDGET : URL_IMG_STATITC_WEB;

//styles
export const CSS_GET_ORG = URL_BASIC_SERVER + "style/org";

//Login
export const LOGIN = URL_BASIC_SERVER + "login/";

//ALL get Data
export const WEB_ALL = URL_BASIC_SERVER + "all/get/webdata";

//versionApp
export const VERSION_APP = URL_BASIC_SERVER + "version/get";

// Championship
export const SEASONS_GET = URL_BASIC_SERVER + "seasons/remote/get";

// Authorization
export const MATCH_AUTHORIZATION = URL_BASIC_SERVER + "matchs/restnetwork";

// Authorization por partido
export const AUTHORIZATION_CODE = URL_BASIC_SERVER + "authorization_code";

//Get User deled remote DB
export const RESTNETWORK_GET_USER =
	URL_BASIC_SERVER + "user/restnetwork/get/by";

export const USER_BY_ORGANIZATION = URL_BASIC_SERVER + "user/get/by";

export const CONTACT_FORM = URL_BASIC_SERVER + "mail/contact/form";

//Champioships get
// export const CHAMPIONSHIP_GET = URL_BASIC_SERVER + "championships/get";
// export const CHAMPIONSHIP_GET = URL_BASIC_SERVER + 'championships/remote/get/'

//Macth-code
// export const MATCH = URL_BASIC_SERVER + "matchs/";

//Macth-code
// export const LEADER_RANKING = URL_BASIC_SERVER + "league_ranking/remote/get/";

//Macth List
// export const MATCH_LIST = URL_BASIC_SERVER + "matchs/list/";

//Matchstatistics
// export const MATCHSTATISTICS_GET = URL_BASIC_SERVER + "match_statistics/get/";

//getByTeamSum
// export const GET_BY_TEAM_SUM = URL_BASIC_SERVER + "match_statistics/sum/";

//getByPlayers
// export const GET_BY_PLAYERS =
// 	URL_BASIC_SERVER + "match_statistics/getByPlayers/";

//Matchstatitics por codigo
// export const MATCHSTATISTICS_BY_CODE =
// 	URL_BASIC_SERVER + "match_statistics/by/";

//get data del Score
// export const MATCHHISTORIES_GET_SCORE =
// 	URL_BASIC_SERVER + "match_histories/getscore/";

// History
// export const HISTORY_BY_MATCH =
// 	URL_BASIC_SERVER + "match_histories/gethistory/";

//Techical
// export const GET_TECHNICAL = URL_BASIC_SERVER + "technical_teams/get/byteam/";

//Refeere
// export const GET_REFEREE_BY_MATCH =
// 	URL_BASIC_SERVER + "referee_commissioners/get/";

//images
//Not Found image
export const IMAGE_NOT_FOUND = STATIC_IMG + "/not-found/image-not-found.jpg";

//Pisture Not Found
export const PICTURE_NOT_FOUND =
	STATIC_IMG + "/not-found/picture-not-found.png";

//Not Found logo
export const LOGO_NOT_FOUND = STATIC_IMG + "/not-found/logo-not-found.png";

//Flag language
export const FLAG_LANGUAGE = STATIC_IMG;
