import React, { useEffect, useState } from "react";
import LeagueItem from "./LeagueItem";

const LeagueRanking = ({ ranking, selectChampionsData, textTranslate }) => {
	const [numberGroup, setNumberGroup] = useState(null);

	const { groupsnumber } = selectChampionsData;

	useEffect(() => {
		let data = [];
		for (let i = 1; i < groupsnumber + 1; i++) {
			data = [...data, i];
		}
		setNumberGroup(data);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{ranking &&
				numberGroup?.map((item) => (
					<React.Fragment key={item}>
						<div key={item.teamcode}>
							<LeagueItem
								group={item}
								groupsnumber={groupsnumber}
								leagueranking={ranking}
								textTranslate={textTranslate}
							/>
						</div>
					</React.Fragment>
				))}
		</>
	);
};

export default LeagueRanking;
