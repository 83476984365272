import React from 'react'

const ScoreNumber = (props) => {
    return (
        <div className="number">
            <div className="text">{props.name}</div>
            <div className="point">{props.score}</div>
        </div>
    );
}

export default ScoreNumber
