import React from "react";

const StateViewColor = ({ state, position, textStateTranslate }) => {
	let className = ` ${position}__state`;

	switch (state) {
		case "LIVE":
			className += ` ${position}__state--live`;
			break;
		case "PREVIUS":
			className += ` ${position}__state--previus`;
			break;
		case "FINISH":
			className += ` ${position}__state--finish`;
			break;
		default:
			break;
	}

	return (
		<div className={"stadeMatch"}>
			<div className={className}>{textStateTranslate}</div>
		</div>
	);
};

export default StateViewColor;
