import * as React from "react";
const Facebook = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		id="Capa_1"
		x={0}
		y={0}
		style={{
			enableBackground: "new 0 0 60.59 62",
		}}
		viewBox="0 0 60.59 62"
		{...props}
	>
		<style>{".st1{display:inline;fill:#fff}"}</style>
		<path
			d="M39.7 10.94c1.88-.06 3.75-.01 5.63-.02h.77V1.16c-1-.1-2.05-.25-3.1-.3-1.92-.09-3.85-.18-5.78-.14-2.94.06-5.72.78-8.15 2.52-2.79 2.01-4.32 4.82-4.9 8.15-.24 1.37-.3 2.79-.34 4.19-.05 2.19-.01 4.38-.01 6.57v.82h-9.34v10.9h9.28v27.4h11.35V33.92h9.25c.48-3.62.94-7.21 1.42-10.95H43.7c-2.63.01-8.67 0-8.67 0s.03-5.4.09-7.75c.1-3.21 2.01-4.19 4.58-4.28z"
			style={{
				fillRule: "evenodd",
				clipRule: "evenodd",
				fill: "#fff",
			}}
		/>
	</svg>
);
export default Facebook;
