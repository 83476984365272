import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import useGetPathBreadcrumbs from "../components/breadcrumb/hook/useGetPathBreadcrumbs";
import { LanguageButton } from "../components/language/LanguageButton";
import ItemSeasons from "../components/cardshow/SeasonsShow/ItemSeasons";
import SkeletonItemSeasons from "../components/cardshow/SeasonsShow/skeleton/SkeletonItemSeasons";
import TitleSection from "../components/titlecomponent/TitleSection";
import { ObjectToUrlParam } from "../components/utilities/ObjectToUrlParam";
import { WEB_ALL } from "../const/Const";
import GlobalContext from "../context/GlobalContext";
import LanguageContext from "../context/LanguageContext";
import useGetCss from "../hook/useGetCss";
import useRequest from "../hook/useRequest";
import useUpdateState from "../hook/useUpdateState";

const Seasons = () => {
	// console.log("render Seasons");

	const { getAllData } = useRequest();

	const { getCssOrg } = useGetCss();

	const { data, setData, setPreviousURLRef, setCurrentPageRef } =
		useContext(GlobalContext);

	const { textTranslate, handleLanguageButton } = useContext(LanguageContext);

	const { Title } = textTranslate?.Seasons;

	let { orderPage, pageRequest, idOrganization } = useParams();

	const { constructorCrumbs } = useGetPathBreadcrumbs();

	const params = useParams();

	const { delStateStorage } = useUpdateState();

	useEffect(() => {
		getCssOrg(pageRequest);
	}, [getCssOrg, pageRequest]);

	const filterorganizations = useCallback(() => {
		if (data) {
			return data.Organizations.find(
				(item) => item.id === parseInt(idOrganization)
			);
		}
	}, [data, idOrganization]);

	const dataBodyOptions = useMemo(() => {
		const items = {
			organization_id: idOrganization,
			page_request: pageRequest,
		};
		return items;
	}, [idOrganization, pageRequest]);

	useEffect(() => {
		setData(null);
		delStateStorage(["data"]);

		getAllData(
			WEB_ALL + "?" + ObjectToUrlParam(dataBodyOptions),
			"GET",
			null,
			null
		);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const hadleClickItemSeason = useCallback(() => {
		setPreviousURLRef(1);
		window.sessionStorage.setItem("previousURLRef", JSON.stringify(1));
		setCurrentPageRef(2);
		window.sessionStorage.setItem("currentPageRef", JSON.stringify(2));
	}, [setPreviousURLRef, setCurrentPageRef]);

	const breadcrumbsList = useMemo(() => {
		return (
			<Breadcrumb
				dataPath={constructorCrumbs(
					params,
					parseInt(orderPage),
					parseInt(pageRequest)
				)}
			/>
		);
	}, [constructorCrumbs, orderPage, params, pageRequest]);

	return (
		<>
			<div className="secondHeader">
				<div className="contentLogin"></div>
				<div className="contentBtnLanguage">
					<LanguageButton
						handleLanguageButton={handleLanguageButton}
					/>
				</div>
			</div>
			<div className="main">
				<div className="contentHome">
					{breadcrumbsList}
					<div className="banner-content w100-s">
						<TitleSection title={Title} />
						{!data ? (
							<div className="payload w100-s center-cross">
								<SkeletonItemSeasons />
							</div>
						) : (
							<ul className="listChampions">
								{data.Seasons?.map((item) => (
									<ItemSeasons
										key={item.id}
										code={item.code}
										name={item.description}
										nameorganizations={
											filterorganizations().name
										}
										hadleClickItemSeason={
											hadleClickItemSeason
										}
										idOrganization={idOrganization}
										pageRequest={pageRequest}
										nextOrderPage={2}
									/>
								))}
							</ul>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Seasons;
