import React from "react";
import PreviousCard from "../card/PreviousCard";

const PreviousTeamsComparison = ({ data, idorganization, textTranslate }) => {
	return (
		<>
			<h2 className="titleText dg-s jcc-s pb24-s">{textTranslate.Title}</h2>
			<div className="headToHead w80-s plr16-s">
				<div className="df-s w100-s jcc-s p8-s border-bottom">
					{data?.PreviousByTeams?.teams?.[0] && (
						<PreviousCard
							item={data?.PreviousByTeams?.teams?.[0]}
							idorganization={idorganization}
						/>
					)}
					<div className="bigger acc-s p16 fw500-s">VS</div>{" "}
					{/* Div de separación */}
					{data?.PreviousByTeams?.teams?.[1] && (
						<PreviousCard
							item={data?.PreviousByTeams?.teams?.[1]}
							idorganization={idorganization}
						/>
					)}
				</div>
				<div className="df-s gg1 jcc-s h100px-s">
					<div className="dg-s p16 w40-s jcfe-s acc-s">
						<div className="fw500-s bigger">
							{data.PreviousByTeams?.total_wins?.team1_total_wins}
						</div>
					</div>
					<div className="dg-s jcc-s p16 smaller w180px-s acc-s">
						{textTranslate.Wins}
					</div>
					<div className="dg-s p16 w40-s jcfs-s acc-s">
						<div className="fw500-s bigger">
							{data.PreviousByTeams?.total_wins?.team2_total_wins}
						</div>
					</div>
				</div>
				<div className="df-s gg1 jcc-s h100px-s">
					<div className="dg-s p16 w40-s jcfe-s acc-s">
						<div className="fw500-s bigger">
							{
								data.PreviousByTeams?.totaltries
									?.team1_total_tries
							}
						</div>
					</div>
					<div className="dg-s jcc-s p16 smaller w180px-s acc-s">
						{textTranslate.Try}
					</div>
					<div className="dg-s p16 w40-s jcfs-s acc-s">
						<div className="fw500-s bigger">
							{
								data.PreviousByTeams?.totaltries
									?.team2_total_tries
							}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PreviousTeamsComparison;
